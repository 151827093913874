import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { tokens } from "../../theme";
import { login, registration, getUserProfile, reset } from '../../features/auth/authSlice';

// Registration form inputs and validation
const registrationSchema = yup.object().shape({
  username: yup.string().required("required"),
  first_name: yup.string().required("required"),
  last_name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
  re_password: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

// Login form inputs and validation
const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

// Initial values for registration form
const initialValuesUserRegistration = {
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  re_password: "",
};

// Initial values for login form
const initialValuesUserLogin = {
  email: "",
  password: "",
};

// Form component
const Form = () => {
  const [pageType, setPageType] = useState("UserLogin");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "UserLogin";
  const isRegistration = pageType === "UserRegistration";

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth);

  // function for when the registration form submit button is clicked
  const UserRegistration = async (values, onSubmitProps) => {
    // send registration form data to backend
    await dispatch(registration(values));

  };

  // if user is registered, set page type to login
  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setPageType("UserLogin");
      toast.success("Registration Successful. Check your email for activation link.");
    }
    dispatch(reset());
  }, [isSuccess, isError, dispatch, message, navigate]
  );


  // function for when the login form submit button is clicked
  const UserLogin = async (values, onSubmitProps) => {
    // send Login form data to backend
    await dispatch(login(values));

  };

  // if user is logged in, redirect to dashboard
  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate("/dashboard");
      dispatch(getUserProfile());
    }
    dispatch(reset());
  }, [isError, isSuccess, dispatch, message, navigate, user]
  );
  

  // function for when the form submit button is clicked
  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await UserLogin(values, onSubmitProps);
    if (isRegistration) await UserRegistration(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isLogin ? initialValuesUserLogin : initialValuesUserRegistration}
      validationSchema={isLogin ? loginSchema : registrationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isRegistration && (
              <>
                <TextField
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name || ''}
                  name="first_name"
                  error={
                    Boolean(touched.first_name) && Boolean(errors.first_name)
                  }
                  helperText={touched.first_name && errors.first_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name || ''}
                  name="last_name"
                  error={Boolean(touched.last_name) && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="User Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username || ''}
                  name="username"
                  error={
                    Boolean(touched.username) && Boolean(errors.username)
                  }
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ''}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ''}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Re-Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.re_password || ''}
                  name="re_password"
                  error={Boolean(touched.re_password) && Boolean(errors.re_password)}
                  helperText={touched.re_password && errors.re_password}
                  sx={{ gridColumn: "span 4" }}
                />
              </>
            )}

            {isLogin && (
              <>
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ''}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ''}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                />
              </>
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: colors.blueAccent[800],
                color: colors.grey[100],
                "&:hover": { color: colors.primary[100] },
                fontSize: "1.2rem",
              }}
            >
              {isLogin ? "LOGIN" : "REGISTER"}
            </Button>
            <Typography
              onClick={() => {
                setPageType(isLogin ? "UserRegistration" : "UserLogin");
                resetForm();
              }}
              sx={{
                textDecoration: "underline",
                color: colors.grey[100],
                "&:hover": {
                  cursor: "pointer",
                  color: colors.greenAccent[100],
                },
              }}
            >
              {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."}
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;