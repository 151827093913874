import React from 'react';
import { Box, } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const AssetModelDetailView = (rowData) => {
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const data = rowData.rowData;

    return (
        <Box
            m="5px"
            sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
        >
            <Box>
                <Box
                    variant="h3"
                    sx={{ color: "text.primary" }}
                >
                    Asset model - <h4>{data.name}</h4> with refference code - <h4>{data.ref_code}</h4> is linked to the asset make - <h4>{data._asset_type}</h4>
                </Box>
                <Box
                    variant="h3"
                    sx={{ color: "text.primary" }}
                >
                    Full Description: <h4>{data.description}</h4>
                </Box>
                {/* <Box
                    component="img"
                    src={categoryData.image}
                    alt={categoryData.name}
                    sx={{ width: "100%", height: "auto", borderRadius: "10px" }}
                /> */}
            </Box>

        </Box>
    );
};

export default AssetModelDetailView;