import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import communicationsAPIService from "./communAPIService";


const initialState = {
    communications: [],
    communication: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create communications
export const createCommunicationEquipment = createAsyncThunk('communications/create',
    async (communicationData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await communicationsAPIService.createCommunicationEquipment(communicationData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update communications
export const updateCommunicationEquipment = createAsyncThunk('communications/update',
    async ({ communicationData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await communicationsAPIService.updateCommunicationEquipment(communicationData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all communications equipment
export const getCommunicationEquipments = createAsyncThunk('communications/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await communicationsAPIService.getCommunicationEquipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const communicationsSlice = createSlice({
    name: 'communications',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create communications
            .addCase(createCommunicationEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createCommunicationEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.communication = action.payload;
                state.message = 'Communication asset created successfully';
            })
            .addCase(createCommunicationEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update communications
            .addCase(updateCommunicationEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateCommunicationEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.communication = action.payload;
                state.message = 'Communication asset updated successfully';
            })
            .addCase(updateCommunicationEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all communications
            .addCase(getCommunicationEquipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCommunicationEquipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.communications = action.payload.results;
                state.message = "";
            })
            .addCase(getCommunicationEquipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = communicationsSlice.actions;
export default communicationsSlice.reducer;