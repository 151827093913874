import axios from "axios";

const CREATE_ASSETMAKE_URL = "/api/v1/entities/asset-make/";
const GET_ASSETMAKES_URL = "/api/v1/entities/asset-make/";
const GET_TYPE_ASSETMAKES_URL = "/api/v1/entities/asset-makebytype/";

//get asset makes
const getAssetMakes = async (token) => {
    const response = await axios.get(GET_ASSETMAKES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get asset makes by type
const getTypeAssetMakes = async (asset_type, token) => {
    const response = await axios.get(`${GET_TYPE_ASSETMAKES_URL}?asset_type=${asset_type}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create make
const createAssetMake = async (assetMakeData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ASSETMAKE_URL, assetMakeData, config);
    return response.data;
};

const assetMakeAPIService = { createAssetMake, getAssetMakes, getTypeAssetMakes };
export default assetMakeAPIService;