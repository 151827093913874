import axios from "axios";

const CREATE_INTANGIBLE_URL = "/api/v1/register/intangible-asset-create/";
const UPDATE_INTANGIBLE_URL = "/api/v1/register/intangible-asset-update/barcode:barcode/";
const GET_INTANGIBLES_URL = "/api/v1/register/intangible-register/";

//get intangible assets
const getIntangibleAssets = async (token) => {
    const response = await axios.get(GET_INTANGIBLES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create intangible asset
const createIntangibleAsset = async (intangibleData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_INTANGIBLE_URL, intangibleData, config);
    return response.data;
};


//update intangible asset
const updateIntangibleAsset = async (intangibleData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_INTANGIBLE_URL.replace("barcode", barcode), intangibleData, config);
    return response.data;
};

const intangibleAssetAPIService = { createIntangibleAsset, updateIntangibleAsset, getIntangibleAssets };

export default intangibleAssetAPIService;