import React, { useState } from "react";
import { tokens } from "../../../theme";
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TopAppBar from "../../global/TopAppBar";
import { Box, Typography, useTheme } from "@mui/material";
// Icons for the Tabs
import SouthAmericaOutlinedIcon from '@mui/icons-material/SouthAmericaOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
// Pages for the Tabs
import RoomsPage from "../rooms/rooms_mrt";
import RegionsPage from "../regions/regions_mrt";
import BranchesPage from "../branch/branches_mrt";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LocationsPage = () => {
  const [setIsSidebar] = useState(true);
  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="app">
      <main className="content">
        <TopAppBar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Box sx={{ width: '100%' }}>
            <Box mb="0.1rem">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="Locations Tabs"
                TabIndicatorProps={{ style: { background: colors.blueAccent[600] } }}
                textColor="inherit"
              >
                <Tab icon={<SouthAmericaOutlinedIcon />} iconPosition="start" label="Regions" {...a11yProps(0)} />
                <Tab icon={<AccountBalanceOutlinedIcon />} iconPosition="start" label="Branches" {...a11yProps(1)} />
                <Tab icon={<MeetingRoomOutlinedIcon />} iconPosition="start" label="Rooms" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <RegionsPage />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BranchesPage />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <RoomsPage />
            </TabPanel>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default LocationsPage;
