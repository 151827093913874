import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryAPIService from "./categoryAPIService";


const initialState = {
    categories: [],
    category: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create category
export const createCategory = createAsyncThunk('categories/create',
    async (categoryData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await categoryAPIService.createCategory(categoryData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all categories
export const getCategories = createAsyncThunk('categories/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            const response = await categoryAPIService.getCategories(token);
            return response;
        } catch (error) {
            console.error(error);
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            return thunkAPI.rejectWithValue({ error: message });
            // throw new Error(message);
        }
    },
);

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create category
            .addCase(createCategory.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.category = action.payload;
                state.message = 'Category created successfully';
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all categories
            .addCase(getCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.categories = action.payload;
                state.message = "";
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = categorySlice.actions;
export default categorySlice.reducer;