import axios from "axios";

const CREATE_BRANCH_URL = "/api/v1/entities/branches/";
const UPDATE_BRANCH_URL = "/api/v1/entities/branches/?pkid=/";
const GET_BRANCHES_URL = "/api/v1/entities/branches/";
const GET_REGION_BRANCHES_URL = "/api/v1/entities/branchesbyregion/";

//get all branchs
const getBranches = async (token) => {
    const response = await axios.get(GET_BRANCHES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get branchs by region
const getRegionBranches = async (region, token) => {
    const response = await axios.get(`${GET_REGION_BRANCHES_URL}?region=${region}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create branch
const createBranch = async (branchData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_BRANCH_URL, branchData, config);
    return response.data;
};

//update branch
const updateBranch = async (branchData, pkid, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.patch(UPDATE_BRANCH_URL, branchData, config);
    return response.data;
};

const branchAPIService = { createBranch, updateBranch, getBranches, getRegionBranches };

export default branchAPIService;