import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transportEquipmentAPIService from "./transportequipmentAPIService";


const initialState = {
    transportequipments: [],
    transportequipment: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create transport equipment
export const createTransportEquipment = createAsyncThunk('transports/create',
    async (transportEquipmentData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await transportEquipmentAPIService.createTransportEquipment(transportEquipmentData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update transport equipment
export const updateTransportEquipment = createAsyncThunk('transports/update',
    async ({ transportEquipmentData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await transportEquipmentAPIService.updateTransportEquipment(transportEquipmentData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all transport equipments
export const getTransportEquipments = createAsyncThunk('transports/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await transportEquipmentAPIService.getTransportEquipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const transportequipmentSlice = createSlice({
    name: 'transportequipment',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create transport equipment
            .addCase(createTransportEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createTransportEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.transportequipment = action.payload;
                state.message = 'Transport equipment created successfully';
            })
            .addCase(createTransportEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update transport equipment
            .addCase(updateTransportEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateTransportEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.transportequipment = action.payload;
                state.message = 'Transport equipment updated successfully';
            })
            .addCase(updateTransportEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all transport equipments
            .addCase(getTransportEquipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTransportEquipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.transportequipments = action.payload.results;
                state.message = "";
            })
            .addCase(getTransportEquipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = transportequipmentSlice.actions;
export default transportequipmentSlice.reducer;