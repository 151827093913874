import axios from "axios";

const CREATE_ICT_URL = "/api/v1/register/ict-asset-create/";
const UPDATE_ICT_URL = "/api/v1/register/ict-asset-update/barcode:barcode/";
const GET_ICTS_URL = "/api/v1/register/ict-register/";

//get ict equipments
const getIctequipments = async (token) => {
    const response = await axios.get(GET_ICTS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create ict equipment
const createIctequipment = async (ictequipmentData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ICT_URL, ictequipmentData, config);
    return response.data;
};


//update ict equipment
const updateIctequipment = async (ictequipmentData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_ICT_URL.replace("barcode", barcode), ictequipmentData, config);
    return response.data;
};

const ictequipmentAPIService = { createIctequipment, updateIctequipment, getIctequipments };

export default ictequipmentAPIService;