import React, { useState } from "react";
import { tokens } from "../../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import TopAppBar from "../../global/TopAppBar";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Icons for the Tabs
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import FitbitOutlinedIcon from '@mui/icons-material/FitbitOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
// Pages for the Tabs
import CategoriesPage from "../categories/categories_mrt";
import SubcategoriesPage from "../subcategories/subcategories_mrt";
import TypesPage from "../types/types_mrt";
import MakesPage from "../makes/makes_mrt";
import ModelsPage from "../models/models_mrt";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CategorizationsPage = () => {
    const [setIsSidebar] = useState(true);
    const [value, setValue] = React.useState(0);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="app">
            <main className="content">
                <TopAppBar setIsSidebar={setIsSidebar} />
                <Box m="20px">
                    <Box sx={{ width: '100%' }}>
                        <Box mb="0.1rem">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                aria-label="Locations Tabs"
                                TabIndicatorProps={{ style: { background: colors.blueAccent[600] } }}
                                textColor="inherit"
                            >
                                <Tab icon={<CategoryOutlinedIcon />} iconPosition="start" label="Categories" {...a11yProps(0)} />
                                <Tab icon={<AccountTreeOutlinedIcon />} iconPosition="start" label="Subcategories" {...a11yProps(1)} />
                                <Tab icon={<FitbitOutlinedIcon />} iconPosition="start" label="Types" {...a11yProps(2)} />
                                <Tab icon={<CoffeeMakerOutlinedIcon />} iconPosition="start" label="Makes" {...a11yProps(3)} />
                                <Tab icon={<HandymanOutlinedIcon />} iconPosition="start" label="Models" {...a11yProps(4)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <CategoriesPage />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <SubcategoriesPage />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <TypesPage />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <MakesPage />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <ModelsPage />
                        </TabPanel>
                    </Box>
                </Box>
            </main>
        </div>
    );
};

export default CategorizationsPage;
