import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Box, IconButton, useTheme } from '@mui/material';
import { ColorModeContext, tokens } from '../../theme';
import InputBase from '@mui/material/InputBase';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';


const Topbar = () => {
    // provide access to the themes
    const theme = useTheme();
    // provide access to the color mode and tokens
    const colors = tokens(theme.palette.mode);
    // provide access to the color mode context
    const colorMode = useContext(ColorModeContext);

    // authentication
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutHandler = async () => {
        await dispatch(logout());
        await dispatch(reset());
        navigate("/login");
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Left side Search Bar */}
            <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box>
            {/* Notifications area */}
            <Box display="flex">
                <ToastContainer/>
            </Box>

            {/* Right Side icons section */}
            <Box display="flex" alignItems="center">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton>
                    <NotificationsNoneOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton onClick={logoutHandler} title='Logout'>
                    <LogoutOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;