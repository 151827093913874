import axios from "axios";

const GET_ASSETS_URL = "/api/v1/register/assets/";

//get assets
const getAssets = async (token) => {

    const response = await axios.get(GET_ASSETS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

const assetAPIService = { getAssets };

export default assetAPIService;