import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";

// Entities reducers
import categoryReducer from "../features/entities/categories/categorySlice";
import subcategoryReducer from "../features/entities/subcategories/subcategorySlice";
import assetTypeReducer from "../features/entities/types/typeSlice";
import assetMakeReducer from "../features/entities/makes/makeSlice";
import assetModelReducer from "../features/entities/models/modelSlice";
import regionReducer from "features/entities/regions/regionSlice";
import branchReducer from "features/entities/branches/branchSlice";
import roomReducer from "features/entities/rooms/roomSlice";
import departmentReducer from "features/entities/departments/departmentSlice";
import userReducer from "features/users/userSlice";

// reports reducers
import assetCaptureReportReducer from "../features/reports/asset-captures/assetCaptureSlice";
import assetVerificationReportReducer from "../features/reports/asset-verification/assetVerificationSlice";
import assetMovementByLocationReportReducer from "../features/reports/movement-location/locationMovementSlice";
import assetMovementByPersonReportReducer from "../features/reports/movement-person/personMovementSlice";

// register reducers
import assetReducer from "../features/register/assets/assetSlice";
import furnitureReducer from "../features/register/furniture/furnitureSlice";
import ictequipmentReducer from "../features/register/ict-equipment/ictequipSlice";
import intangibleassetReducer from "../features/register/intangible/intangibleSlice";
import machineryReducer from "../features/register/machinery/machinerySlice";
import officeEquipmentReducer from "../features/register/office-equipment/officeequipSlice";
import specializedBuildingReducer from "../features/register/specialized-buildings/specializedbuildingSlice";
import transportequipmentReducer from "../features/register/transport-equipment/transportequipmentSlice";
import communicationsReducer from "features/register/communication/communSlice";
import verifiedAssetSlice from "features/register/verification/verificationSlice";
// import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = { key: "root", storage, version: 1 };
const persistedReducer = persistReducer(persistConfig, combineReducers({
    auth: authReducer,
    // entities
    categories: categoryReducer,
    subcategories: subcategoryReducer,
    assettypes: assetTypeReducer,
    assetmakes: assetMakeReducer,
    assetmodels: assetModelReducer,
    regions: regionReducer,
    branches: branchReducer,
    rooms: roomReducer,
    departments: departmentReducer,
    // users
    users: userReducer,
    // register
    assets: assetReducer,
    furnitures: furnitureReducer,
    ictequipments: ictequipmentReducer,
    intangibleassets: intangibleassetReducer,
    machineries: machineryReducer,
    officeEquipments: officeEquipmentReducer,
    specializedbuildings: specializedBuildingReducer,
    transportequipments: transportequipmentReducer,
    communications: communicationsReducer,
    verifiedAsset: verifiedAssetSlice,
    // reports
    assetCaptureReports: assetCaptureReportReducer,
    assetVerificationReports: assetVerificationReportReducer,
    assetMovementByLocationReports: assetMovementByLocationReportReducer,
    assetMovementByPersonReports: assetMovementByPersonReportReducer,
}));

// const store = configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware(
//         { serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] } }),
// });
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    }),
});

export default store;