import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetCaptureReportAPIService from './assetCaptureAPIService';


const initialState = {
    assetCaptureReports: [],
    assetCaptureReport: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};


// get asset movement by location report
export const getAssetCaptureReport = createAsyncThunk(
    'report/assetCaptureReport',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetCaptureReportAPIService.getCapturedAssetsReport(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            throw new Error(message);
        }
    }
);

// create slice
const assetCaptureReportSlice = createSlice({
    name: 'assetCaptureReport',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // get asset movement by location report
            .addCase(getAssetCaptureReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetCaptureReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetCaptureReports = action.payload;
                state.message = 'Asset movement by location report loaded successfully';
            })
            .addCase(getAssetCaptureReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetCaptureReportSlice.actions;
export default assetCaptureReportSlice.reducer;