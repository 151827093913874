import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetAPIService from "./verificationAPIService";


const initialState = {
    verifiedAsset: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


// get asset to verify
export const getAsset = createAsyncThunk('assets/getSingle',
    async (assetCode, thunkAPI) => {
        console.log("API slice assetCode", assetCode);
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetAPIService.getAsset(assetCode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

// create verification report entry
export const updateVerificationReport = createAsyncThunk('reports/update',
    async (assetData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetAPIService.updateVerificationReport(assetData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

// Reset back to initial state after verification run
export const clearVerificationStatus = createAsyncThunk('assets/clearStatus',
    async () => {
        // no need to dispatch any action here
        //check if clear verification is dispatched
        console.log("Clearing verification status action reached...");
    }
);

export const verifiedAssetSlice = createSlice({
    name: 'verifiedAsset',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //get single asset
            .addCase(getAsset.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "Loading in progress...";
            })
            .addCase(getAsset.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.verifiedAsset = action.payload;
                state.message = "Asset verified successfully!";
            })
            .addCase(getAsset.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.verifiedAsset = action.payload;
                state.message = "Asset verification failed!";
            })
            // update verification report
            .addCase(updateVerificationReport.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "Updating verification report...";
            })
            .addCase(updateVerificationReport.fulfilled, (state) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = "Verification report updated successfully!";
            })
            .addCase(updateVerificationReport.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = "Failed to update verification report!";
            })
            // clear verification status
            .addCase(clearVerificationStatus.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "Clearing verification status...";
            })
            .addCase(clearVerificationStatus.fulfilled, (state) => {
                console.log("Clearing verification fulfilled case reached...");
                console.log("State before clearing:", state);
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = "";
                state.verifiedAsset = [];
                console.log("State after clearing:", state);
            })
            .addCase(clearVerificationStatus.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = "Failed to clear verification status!";
            });
    },
});

export const { reset } = verifiedAssetSlice.actions;
export default verifiedAssetSlice.reducer;