import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import furnitureAPIService from "./furnitureAPIService";


const initialState = {
    furnitures: [],
    furniture: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create furniture
export const createFurniture = createAsyncThunk('furnitures/create',
    async (furnitureData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await furnitureAPIService.createFurniture(furnitureData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update furniture
export const updateFurniture = createAsyncThunk('furnitures/update',
    async ({ furnitureData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await furnitureAPIService.updateFurniture(furnitureData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all furnitures
export const getFurnitures = createAsyncThunk('furnitures/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await furnitureAPIService.getFurnitures(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const furnitureSlice = createSlice({
    name: 'furniture',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create furniture
            .addCase(createFurniture.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createFurniture.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.furniture = action.payload;
                state.message = 'Furniture created successfully';
            })
            .addCase(createFurniture.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update furniture
            .addCase(updateFurniture.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateFurniture.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.furniture = action.payload;
                state.message = 'Furniture updated successfully';
            })
            .addCase(updateFurniture.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all furnitures
            .addCase(getFurnitures.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFurnitures.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.furnitures = action.payload.results;
                state.message = "";
            })
            .addCase(getFurnitures.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = furnitureSlice.actions;
export default furnitureSlice.reducer;