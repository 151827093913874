import React from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../../components/Header";
import LineChart from "../../../components/LineChart";
import { tokens } from "../../../theme";
import { Box, Button, useTheme } from "@mui/material";

const Line = () => {
    const themes = useTheme();
    const colors = tokens(themes.palette.mode);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Assets Movement" subtitle="Types Movement by District" />
                <Box>
                    <Button
                        sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Report
                    </Button>
                </Box>
            </Box>
            <Box height="75vh">
                <LineChart />
            </Box>
        </Box>

    );
};

export default Line;