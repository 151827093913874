import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik } from 'formik';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, TextField } from "@mui/material";
// import { IconButton } from "@mui/material";

import { getAsset } from "../../../features/register/verification/verificationSlice";
import { clearVerificationStatus } from "../../../features/register/verification/verificationSlice";
import { updateVerificationReport } from "../../../features/register/verification/verificationSlice";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
// import CloseIcon from '@mui/icons-material/Close';


const initialValues = {
    barcode: "",
};

const assetVerificationSchema = yup.object().shape({
    barcode: yup.string().required("Barcode is required"),
});

const AssetVerificationform = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    // access logged in user
    const user = useSelector((state) => state.auth.user);
    // time and date
    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const date_verified = `${day}-${month}-${year}`;
    const time_verified = date.toLocaleTimeString('en-US', { hour12: false });

    // alert message
    const { isSuccess, message } = useSelector((state) => state.verifiedAsset);
    const { verifiedAsset } = useSelector((state) => state.verifiedAsset)

    useEffect(() => {
        if (isSuccess && !message) {
            setOpen(true);
        }
    }, [isSuccess, message]);

    console.log(verifiedAsset);

    const asset = verifiedAsset[0];

    console.log(asset);

    const handleNotFound = () => {
        console.log('handle close called')
        // dispatch action to clear / reset message and verificationAsset array
        dispatch(clearVerificationStatus());
    }

    // handle asset verification and report update
    const handleVerifyAsset = async (token) => {
        console.log('handle verify asset called')
        // get asset data
        const assetData = {
            barcode: asset.barcode,
            tag_number: asset.tag_number,
            description: asset.description,
            verified_at: asset.location,
            verified_by: user.username,
            date_verified: date_verified,
            time_verified: time_verified,
        };
        // send report update data
        await dispatch(updateVerificationReport(assetData, token));
        // dispatch action to clear / reset message and verificationAsset array
        await dispatch(clearVerificationStatus());
    }

    const handleFormSubmit = async (barcode, onSubmitProps) => {
        console.log('handle submit called')
        // send new form data to backend
        const assetCode = barcode.barcode;
        await dispatch(getAsset(assetCode));
        onSubmitProps.resetForm(); // Clear the form
        setOpen(true); // Re-open the alert
    };

    return (
        <Box m="10px">
            <Box mb="1rem">
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {isSuccess && !verifiedAsset.length > 0 ? (
                            <Alert
                                severity="error"
                            // action={
                            //     <IconButton
                            //         aria-label="close"
                            //         color="inherit"
                            //         size="small"
                            //         // onClick={() => { setOpen(false); }}
                            //         onClick={handleClose}
                            //     >
                            //         <CloseIcon fontSize="inherit" />
                            //     </IconButton>
                            // }
                            >
                                <strong>Asset not found or verification failed!</strong><br />
                                <strong>Please Capture the Asset</strong>
                            </Alert>
                        ) : (null)}
                        {isSuccess && verifiedAsset.length > 0 ? (
                            <Alert
                                severity="success"
                            // action={
                            //     <IconButton
                            //         aria-label="close"
                            //         color="inherit"
                            //         size="small"
                            //         // onClick={() => { setOpen(false); }}
                            //         onClick={handleClose}
                            //     >
                            //         <CloseIcon fontSize="inherit" />
                            //     </IconButton>
                            // }
                            >
                                <strong>{message}</strong><br />
                                <strong>Asset Code: {asset.tag_number}</strong><br />
                                <strong>Description: {asset.description}</strong><br />
                                <strong>Verified at: {asset.location}</strong>
                            </Alert>
                        ) : (null)}
                    </Stack>
                </Collapse>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={assetVerificationSchema}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Barcode"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.barcode}
                                name="barcode"
                                error={formik.touched.barcode && !!formik.errors.barcode}
                                helperText={formik.touched.barcode && formik.errors.barcode}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            {open && !message && (
                                <Button fullWidth type="submit" color="secondary" variant="contained">
                                    Check for Asset
                                </Button>
                            )}
                            {isSuccess && verifiedAsset.length > 0 && (
                                <Button fullWidth onClick={handleVerifyAsset} color="secondary" variant="contained">
                                    Verify Asset
                                </Button>
                            )}
                            {isSuccess && !verifiedAsset.length > 0 && (
                                <Button fullWidth onClick={handleNotFound} color="secondary" variant="contained">
                                    Add Asset
                                </Button>
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AssetVerificationform;
