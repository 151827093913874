import React, { useState } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../../components/Header";
import BarChart from "../../../components/BarChart";
import { tokens } from "../../../theme";
import { Box, Button } from "@mui/material";

import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "../../global/Topbar";
import Sidebar from "../../global/Sidebar";

const Bar = () => {
    const { theme, colorMode } = useMode();
    const colors = tokens(theme.palette.mode);

    const [isSidebar, setIsSidebar] = useState(true);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <Sidebar isSidebar={isSidebar} />
                    <main className="content">
                        <Topbar setIsSidebar={setIsSidebar} />
                        <Box m="20px">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Header title="Asset Categories" subtitle="Distribution by District" />
                                <Box>
                                    <Button
                                        sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        }}
                                    >
                                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                                        Report
                                    </Button>
                                </Box>
                            </Box>
                            <Box height="75vh">
                                <BarChart />
                            </Box>
                        </Box>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>

    );
};

export default Bar;