import axios from 'axios';

const GET_ASSET_MOVEMENT_BY_LOCATION_URL = "/api/v1/reports/asset-movement-by-location/";

// get asset location movement report
const getAssetMovementByLocationReport = async (token) => {
    const response = await axios.get(GET_ASSET_MOVEMENT_BY_LOCATION_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

const assetMovementByLocationReportAPIService = { getAssetMovementByLocationReport };
export default assetMovementByLocationReportAPIService;