import axios from "axios";

const CREATE_ASSET_VERIFICATION_REPORT_URL = "/api/v1/reports/asset-verification/";
const GET_ASSET_VERIFICATION_REPORT_URL = "/api/v1/reports/asset-verification/";

// get asset verification report
const getAssetVerificationReport = async (token) => {
    const response = await axios.get(GET_ASSET_VERIFICATION_REPORT_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

// create asset verification report
const createAssetVerificationReport = async (assetVerificationData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ASSET_VERIFICATION_REPORT_URL, assetVerificationData, config);
    return response.data;
};

const AssetVerificationReportAPIService = { createAssetVerificationReport, getAssetVerificationReport };

export default AssetVerificationReportAPIService;