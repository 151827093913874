import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { Formik } from 'formik';
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { getAssetMakes } from "../../../features/entities/makes/makeSlice";
import { createAssetModel } from "../../../features/entities/models/modelSlice";


const initialValues = {
    ref_code: "abcd",
    slug: "abcd",
    name: "",
    description: "",
    asset_make: "",
};

const assetModelSchema = yup.object().shape({
    ref_code: yup.string().required("Ref_code is required"),
    slug: yup.string().required("slug is required"),
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    asset_make: yup.string().required("Asset Make is required"),
});

const AddAssetModelForm = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const { assetmakes } = useSelector((state) => state.assetmakes);
    const { isError, isSuccess, message } = useSelector((state) => state.assetmodels);


    useEffect(() => {
        dispatch(getAssetMakes())
    }, [dispatch]);


    const handleFormSubmit = async (values, onSubmitProps) => {
        // send new form data to backend
        await dispatch(createAssetModel(values));
        onSubmitProps.resetForm(); // Clear the form
        setOpen(true); // Re-open the alert
    };

    return (
        <Box m="10px">
            <Box mb="1rem">
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {isError && isError.length > 0 ? (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                        {isSuccess && isSuccess.length > 0 ? (
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{isSuccess}</strong>
                            </Alert>
                        ) : (null)}
                        {message && message.length > 0 ? (
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                    </Stack>
                </Collapse>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={assetModelSchema}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                hidden
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.ref_code}
                                name="ref_code"
                                error={!!formik.touched.ref_code && !!formik.errors.ref_code}
                                helperText={formik.touched.ref_code && formik.errors.ref_code}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.slug}
                                name="slug"
                                error={!!formik.touched.slug && !!formik.errors.slug}
                                helperText={formik.touched.slug && formik.errors.slug}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                error={!!formik.touched.name && !!formik.errors.name}
                                helperText={formik.touched.name && formik.errors.name}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Description"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                error={!!formik.touched.description && !!formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select label="Asset Make"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.asset_make}
                                name="asset_make"
                                error={!!formik.touched.asset_make && !!formik.errors.asset_make}
                                helperText={formik.touched.asset_make && formik.errors.asset_make}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {assetmakes.map((asset_make) => (<MenuItem key={asset_make.pkid} value={asset_make.pkid}>{asset_make.name}</MenuItem>))}
                            </TextField>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button fullwidth="true" type="submit" color="secondary" variant="contained">
                                Create Asset Model
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AddAssetModelForm;