import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor="transparent"
        p="1rem 6%"
        mt="1rem"
        mb="0rem"
        textAlign="center"
      >
        {/* <img src={require('../../images/logo/arc3WhiteFullSm.png')} alt='ARC+' /> */}
        <img src={require('../../images/logo/logo-4-full.png')} alt='ARC+' />
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="1rem 2rem 1rem 2rem"
        m="1rem auto"
        textAlign="center"
        borderRadius="1.5rem"
        backgroundColor={colors.primary[400]}
      >
        <Box sx={{ mb: '1rem', mt: '1rem', }}>
          {/* <img src={require('../../images/logo/logo-4-full.png')} alt='ARC+' /> */}
        </Box>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;