import React from "react";
import { Routes, Route } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import ProtectedRoute from "./features/auth/ProtectRoute";

import FAQ from "./pages/faq";
import Form from "./pages/form";
import Users from "./pages/users";
import Invoices from "./pages/invoices";
import Calendar from "./pages/calendar";
import Geography from "./pages/charts/geography";
import Pie from "./pages/charts/pie";
import Bar from "./pages/charts/bar";
import Line from "./pages/charts/line";
import Dashboard from "./pages/dashboard";
import NotFound from "./components/NotFound";
import NoModuleAccess from "./components/NoModuleAccess";
import Register from "./pages/register/assets";
import AssetsReports from "pages/reports/assets-reports";
import Categorizations from "./pages/entities/categorizations";
import Locations from "./pages/entities/locations";
import LoginPage from "pages/login";
import AccountActivation from "pages/login/activate";
import DjangoAdminPanel from "pages/djangoAdmin";


const App = () => {
  const { theme, colorMode } = useMode();

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/activate/:uid/:token" element={<AccountActivation />} />
            <Route path="/arcadmin"
              element={
                <ProtectedRoute>
                  <DjangoAdminPanel />
                </ProtectedRoute>}
            />
            <Route path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>}
            />
            <Route path="/register"
              element={
                <ProtectedRoute>
                  <Register />
                </ProtectedRoute>}
            />
            <Route path="/entities/categorizations"
              element={
                <ProtectedRoute>
                  <Categorizations />
                </ProtectedRoute>}
            />
            <Route path="/entities/locations"
              element={
                <ProtectedRoute>
                  <Locations />
                </ProtectedRoute>}
            />
            <Route path="/reports/assets"
              element={
                <ProtectedRoute>
                  <AssetsReports />
                </ProtectedRoute>}
            />
            <Route path="/faq"
              element={
                <ProtectedRoute>
                  <FAQ />
                </ProtectedRoute>}
            />
            <Route path="/upgrade"
              element={
                <ProtectedRoute>
                  <NoModuleAccess />
                </ProtectedRoute>}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/form" element={<Form />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/line" element={<Line />} />
            <Route path="/geography" element={<Geography />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default App;
