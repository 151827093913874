import axios from "axios";

const CREATE_OFFICE_EQUIPMENT_URL = "/api/v1/register/office-asset-create/";
const UPDATE_OFFICE_EQUIPMENT_URL = "/api/v1/register/office-asset-update/barcode:barcode/";
const GET_OFFICE_EQUIPMENTS_URL = "/api/v1/register/office-register/";

//get office equipments
const getOfficeEquipments = async (token) => {
    const response = await axios.get(GET_OFFICE_EQUIPMENTS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create office equipment
const createOfficeEquipment = async (officeEquipmentData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_OFFICE_EQUIPMENT_URL, officeEquipmentData, config);
    return response.data;
};


//update office equipment
const updateOfficeEquipment = async (officeEquipmentData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_OFFICE_EQUIPMENT_URL.replace("barcode", barcode), officeEquipmentData, config);
    return response.data;
};

const officeEquipmentAPIService = { createOfficeEquipment, updateOfficeEquipment, getOfficeEquipments };

export default officeEquipmentAPIService;