import axios from "axios";

const GET_ASSET_URL = "/api/v1/register/search/?search_query=";
const UPDATE_VERIFICATION_REPORT_URL = "/api/v1/reports/asset-verification/";

//get asset to verify
const getAsset = async (assetCode, token) => {
    console.log('API service barcode:', assetCode);
    const response = await axios.get(GET_ASSET_URL + assetCode, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    console.log('API barcode:', assetCode);
    return response.data
};

// create verification report entry
const updateVerificationReport = async (assetData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(UPDATE_VERIFICATION_REPORT_URL, assetData, config);
    return response.data;
};


const assetAPIService = { getAsset, updateVerificationReport };

export default assetAPIService;