import { tokens } from "../theme";

export const mockLineData = [
    {
        id: "Kampala",
        color: tokens("dark").greenAccent[500],
        data: [
            {
                x: "desktops",
                y: 101,
            },
            {
                x: "laptops",
                y: 75,
            },
            {
                x: "monitors",
                y: 36,
            },
            {
                x: "deskphones",
                y: 216,
            },
            {
                x: "printers",
                y: 35,
            },
            {
                x: "chairs",
                y: 236,
            },
            {
                x: "workstations",
                y: 88,
            },
            {
                x: "desks",
                y: 232,
            },
            {
                x: "tables",
                y: 281,
            },
            {
                x: "cabinets",
                y: 1,
            },
            {
                x: "shelves",
                y: 35,
            },
            {
                x: "others",
                y: 14,
            },
        ],
    },
    {
        id: "mbarara",
        color: tokens("dark").blueAccent[300],
        data: [
            {
                x: "desktops",
                y: 212,
            },
            {
                x: "laptops",
                y: 190,
            },
            {
                x: "monitors",
                y: 270,
            },
            {
                x: "deskphones",
                y: 9,
            },
            {
                x: "printers",
                y: 75,
            },
            {
                x: "chairs",
                y: 175,
            },
            {
                x: "workstations",
                y: 33,
            },
            {
                x: "desks",
                y: 189,
            },
            {
                x: "tables",
                y: 97,
            },
            {
                x: "cabinets",
                y: 87,
            },
            {
                x: "shelves",
                y: 299,
            },
            {
                x: "others",
                y: 251,
            },
        ],
    },
    {
        id: "arua",
        color: tokens("dark").redAccent[200],
        data: [
            {
                x: "desktops",
                y: 191,
            },
            {
                x: "laptops",
                y: 136,
            },
            {
                x: "monitors",
                y: 91,
            },
            {
                x: "deskphones",
                y: 190,
            },
            {
                x: "printers",
                y: 211,
            },
            {
                x: "chairs",
                y: 152,
            },
            {
                x: "workstations",
                y: 189,
            },
            {
                x: "desks",
                y: 152,
            },
            {
                x: "tables",
                y: 8,
            },
            {
                x: "cabinets",
                y: 197,
            },
            {
                x: "shelves",
                y: 107,
            },
            {
                x: "others",
                y: 170,
            },
        ],
    },
];