import React, { useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

import TopAppBar from "../global/TopAppBar";

const FAQ = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [setIsSidebar] = useState(true);

    return (
        <div className="app">
            <main className="content">
                <TopAppBar setIsSidebar={setIsSidebar} />
                <Box m="20px">
                    <Header title="FAQ" subtitle="FAQ on Arcplus and Asset Management" />
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color={colors.greenAccent[500]} variant="h5">
                                What is Arcplus?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Arcplus is a platform that allows you to manage your assets and track their performance. It is a one-stop solution for all your asset management needs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color={colors.greenAccent[500]} variant="h5">
                                What is Asset Management?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Asset management is the process of managing your assets to maximize their value. It involves tracking the performance of your assets and making decisions to improve their performance.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color={colors.greenAccent[500]} variant="h5">
                                What is best way to perform an asset verification?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The best way to perform an asset verification is to use the Arcplus platform. It allows you to track the performance of your assets and make decisions to improve their performance.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color={colors.greenAccent[500]} variant="h5">
                                What unique features has Arcplus got for asset management?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Arcplus has got barcode scanning, QR code scanning, and image recognition features to help you manage your assets. It also has a dashboard that allows you to track the performance of your assets and make decisions to improve their performance.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </main>
        </div>
    );
};

export default FAQ;
