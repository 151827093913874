import axios from "axios";

const CREATE_CATEGORY_URL = "/api/v1/entities/asset-categories/";
const GET_CATEGORIES_URL = "/api/v1/entities/asset-categories/";

//get categories
// const getCategories = async (token) => {
//     const response = await axios.get(GET_CATEGORIES_URL, {
//         headers: {
//             "Authorization": `Bearer ${token}`
//         }
//     });
//     return response.data
// };

const getCategories = async (token, page = 1, categories = []) => {
    const response = await axios.get(GET_CATEGORIES_URL, {
        params: {
            page: page
        },
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    const newCategories = categories.concat(response.data);

    if (response.data.nextPage) {
        return getCategories(token, page + 1, newCategories);
    } else {
        return newCategories;
    }
};


//create category
const createCategory = async (categoryData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_CATEGORY_URL, categoryData, config);
    return response.data;
};

const categoryAPIService = { createCategory, getCategories };

export default categoryAPIService;