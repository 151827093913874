import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import specializedBuildingAPIService from "./specializedbuildingAPIService";


const initialState = {
    specializedbuildings: [],
    specializedbuilding: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create specialized building
export const createSpecializedBuilding = createAsyncThunk('specializeds/create',
    async (specializedBuildingData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await specializedBuildingAPIService.createSpecializedBuilding(specializedBuildingData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update specialized building
export const updateSpecializedBuilding = createAsyncThunk('specializeds/update',
    async ({ specializedBuildingData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await specializedBuildingAPIService.updateSpecializedBuilding(specializedBuildingData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all specialized buildings
export const getSpecializedBuildings = createAsyncThunk('specializeds/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await specializedBuildingAPIService.getSpecializedBuildings(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const specializedBuildingSlice = createSlice({
    name: 'specializedbuilding',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create specialized building
            .addCase(createSpecializedBuilding.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createSpecializedBuilding.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.specialized = action.payload;
                state.message = 'Specialized building created successfully';
            })
            .addCase(createSpecializedBuilding.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update specialized building
            .addCase(updateSpecializedBuilding.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateSpecializedBuilding.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.specialized = action.payload;
                state.message = 'Specialized building updated successfully';
            })
            .addCase(updateSpecializedBuilding.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all specialized buildings
            .addCase(getSpecializedBuildings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSpecializedBuildings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.specializedbuildings = action.payload.results;
                state.message = "";
            })
            .addCase(getSpecializedBuildings.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = specializedBuildingSlice.actions;
export default specializedBuildingSlice.reducer;