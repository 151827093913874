import React from 'react';
import { tokens } from "../../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Pages for each register tab

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MovementByPersonDetailView = (rowData) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [value, setValue] = React.useState(0);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const data = rowData.rowData;

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    width="100%"
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons={isNonMobile ? 'auto' : 'on'}
                    allowScrollButtonsMobile
                    aria-label='Movement By Person Report Tabs'
                    TabIndicatorProps={{ style: { backgroundColor: colors.greenAccent[500] } }}
                    textColor='inherit'
                >
                    <Tab label='Details' {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel width="100%" value={value} index={0}>
                <Box>
                    <Typography variant='h4'>
                        {data.asset_make}
                    </Typography>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            <b>Barcode:</b> {data.barcode}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Tag Number:</b> {data.tag_number}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Description:</b> {data.description}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Person From:</b> {data.person_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Person To:</b> {data.person_to}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Issued By:</b> {data.captured_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Date Issued:</b> {data.date_issued}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Time Issued:</b> {data.time_issued}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
    );
};

export default MovementByPersonDetailView;