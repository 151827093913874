import React from 'react';
import { tokens } from "../../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Pages for each register tab

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AssetDetailView = (rowData) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [value, setValue] = React.useState(0);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const data = rowData.rowData;

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    width="100%"
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons={isNonMobile ? 'auto' : 'on'}
                    allowScrollButtonsMobile
                    aria-label='Asset Register Tabs'
                    TabIndicatorProps={{ style: { backgroundColor: colors.greenAccent[500] } }}
                    textColor='inherit'
                >
                    <Tab label='Details' {...a11yProps(0)} />
                    <Tab label='Accounting' {...a11yProps(1)} />
                    <Tab label='History' {...a11yProps(2)} />
                    <Tab label='Documents' {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel width="100%" value={value} index={0}>
                <Box>
                    <Typography variant='h4'>
                        {data.asset_make} {data.asset_type}
                    </Typography>
                    <Box>
                        <img src={data.asset_image} alt={data.barcode} style={{ width: "100%", height: "auto", borderRadius: "10px", m: "1.5rem" }} />
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Barcode: {data.barcode}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Description: {data.description}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            User: {data.user}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Location: {data.location}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            GPS Coordinates: {data.gps_coordinates}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Status: {data.status}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Condition: {data.condition}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Deployment Date: {data.deployment_date}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Purchase Price: {data.cost}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Current Value: {data.cost}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Depreciation: {data.depreciation}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Date Purchased: {data.purchase_date}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Warranty Expiry: {data.warranty_expiry}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Last Updated: {data.last_updated}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Updated By: {data.updated_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Created By: {data.created_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Document Name: {data.document_name}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
    );
};

export default AssetDetailView;