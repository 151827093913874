import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useField, useFormikContext } from "formik";

const FormikDatePicker = (props) => {
  const { name, ...restProps } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...restProps}
        value={field.value ?? null}
        onChange={(val) => setFieldValue(name, val)}
        />
    </LocalizationProvider>
  );
};

export default FormikDatePicker;
