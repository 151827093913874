import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import machineryAPIService from "./machineryAPIService";


const initialState = {
    machineries: [],
    machinery: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create machinery
export const createMachineryEquipment = createAsyncThunk('machineries/create',
    async (machineryData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await machineryAPIService.createMachineryEquipment(machineryData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update machinery
export const updateMachineryEquipment = createAsyncThunk('machineries/update',
    async ({ machineryData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await machineryAPIService.updateMachineryEquipment(machineryData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all machinery equipment
export const getMachineryEquipments = createAsyncThunk('machineries/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await machineryAPIService.getMachineryEquipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const machinerySlice = createSlice({
    name: 'machinery',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create machinery
            .addCase(createMachineryEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createMachineryEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.machinery = action.payload;
                state.message = 'Machinery equipment created successfully';
            })
            .addCase(createMachineryEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update machinery
            .addCase(updateMachineryEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateMachineryEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.machinery = action.payload;
                state.message = 'Machinery equipment updated successfully';
            })
            .addCase(updateMachineryEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all machineries
            .addCase(getMachineryEquipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMachineryEquipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.machineries = action.payload.results;
                state.message = "";
            })
            .addCase(getMachineryEquipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = machinerySlice.actions;
export default machinerySlice.reducer;