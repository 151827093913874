import axios from "axios";

const CREATE_FURNITURE_URL = "/api/v1/register/furniture-asset-create/";
const UPDATE_FURNITURE_URL = "/api/v1/register/furniture-asset-update/barcode:barcode/";
const GET_FURNITURES_URL = "/api/v1/register/furniture-register/";

//get furnitures
const getFurnitures = async (token) => {
    const response = await axios.get(GET_FURNITURES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create furniture
const createFurniture = async (furnitureData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_FURNITURE_URL, furnitureData, config);
    return response.data;
};

//update furniture
const updateFurniture = async (furnitureData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_FURNITURE_URL.replace("barcode", barcode), furnitureData, config);
    return response.data;
};

const furnitureAPIService = { createFurniture, updateFurniture, getFurnitures };

export default furnitureAPIService;