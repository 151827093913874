import React from "react";
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, useTheme, IconButton } from "@mui/material";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { tokens } from "../theme";

const NoModuleAccess = ({ title, subtitle }) => {
    const themes = useTheme();
    const navigate = useNavigate();
    const colors = tokens(themes.palette.mode);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100%',
            }}
        >
            <IconButton>
                <ReportProblemOutlinedIcon
                    sx={{
                        fontSize: "100px",
                        color: colors.greenAccent[600]
                    }}
                />
            </IconButton>
            <Typography
                variant="h1"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ mb: "10px" }}
                icon
            >
                This module is not part of your package
            </Typography>
            <Typography
                variant="h3"
                color={colors.greenAccent[400]}
            >
                Upgrade to unlock additional functionality
            </Typography>
            <Box mt="2rem">
                <Button
                    onClick={() => navigate("/dashboard")}
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "normal",
                        padding: "8px 20px",
                    }}
                >
                    <InsightsOutlinedIcon sx={{ mr: "1rem", fontSize: "2rem" }} />
                    Take me to my Dashboard
                </Button>
            </Box>
        </Box>
    );
};

export default NoModuleAccess;