import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AssetVerificationReportAPIService from './assetVerificationAPIService';


const initialState = {
    assetVerificationReports: [],
    assetVerificationReport: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
};

// create asset verification report
export const createAssetVerificationReport = createAsyncThunk('report/createAssetVerificationReport',
    async (assetVerificationData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await AssetVerificationReportAPIService.createAssetVerificationReport(assetVerificationData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            throw new Error(message);
        }
    }
);

// get asset verification report
export const getAssetVerificationReport = createAsyncThunk('report/assetVerificationReport',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await AssetVerificationReportAPIService.getAssetVerificationReport(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            throw new error(message);
        }
    }
);

// create slice
const assetVerificationReportSlice = createSlice({
    name: 'assetVerificationReport',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // create asset verification report
            .addCase(createAssetVerificationReport.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = 'Creating asset verification report...';
            })
            .addCase(createAssetVerificationReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetVerificationReport = action.payload;
                state.message = 'Verification report created successfully';
            })
            .addCase(createAssetVerificationReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })

            // get asset verification report
            .addCase(getAssetVerificationReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetVerificationReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetVerificationReports = action.payload;
                state.message = 'Asset verification report fetched successfully';
            })
            .addCase(getAssetVerificationReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetVerificationReportSlice.actions;
export default assetVerificationReportSlice.reducer;