import axios from "axios";

const CREATE_COMMUNICATIONS_URL = "/api/v1/register/communications-asset-create/";
const UPDATE_COMMUNICATIONS_URL = "/api/v1/register/communications-asset-update/barcode:barcode/";
const GET_COMMUNICATIONS_URL = "/api/v1/register/communications-register/";

//get communications equipments
const getCommunicationEquipments = async (token) => {
    const response = await axios.get(GET_COMMUNICATIONS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create communications
const createCommunicationEquipment = async (communicationsData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_COMMUNICATIONS_URL, communicationsData, config);
    return response.data;
};

//update communications
const updateCommunicationEquipment = async (communicationsData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_COMMUNICATIONS_URL.replace("barcode", barcode), communicationsData, config);
    return response.data;
};


const communicationsAPIService = { createCommunicationEquipment, updateCommunicationEquipment, getCommunicationEquipments };

export default communicationsAPIService;