import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetMakeAPIService from "./makeAPIService";


const initialState = {
    assetmakes: [],
    assetmake: {},
    typeassetmakes: [],
    typeassetmake: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create asset make
export const createAssetMake = createAsyncThunk('assetmake/create',
    async (assetMakeData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetMakeAPIService.createAssetMake(assetMakeData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all asset makes
export const getAssetMakes = createAsyncThunk('assetmake/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetMakeAPIService.getAssetMakes(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get asset makes by type
export const getTypeAssetMakes = createAsyncThunk('assetmake/getByType',
    async (asset_type, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetMakeAPIService.getTypeAssetMakes(asset_type, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const assetMakeSlice = createSlice({
    name: 'assetmake',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create asset make
            .addCase(createAssetMake.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createAssetMake.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.category = action.payload;
                state.message = 'Asset Make created successfully';
            })
            .addCase(createAssetMake.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all asset makes
            .addCase(getAssetMakes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetMakes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetmakes = action.payload;
                state.message = "";
            })
            .addCase(getAssetMakes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get asset makes by type
            .addCase(getTypeAssetMakes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTypeAssetMakes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.typeassetmakes = action.payload;
                state.message = "";
            })
            .addCase(getTypeAssetMakes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetMakeSlice.actions;
export default assetMakeSlice.reducer;