import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import officeEquipmentAPIService from "./officeequipAPIService";


const initialState = {
    officeEquipments: [],
    officeEquipment: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create office equipment
export const createOfficeEquipment = createAsyncThunk('officeequipments/create',
    async (officeEquipmentData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await officeEquipmentAPIService.createOfficeEquipment(officeEquipmentData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update office equipment
export const updateOfficeEquipment = createAsyncThunk('officeequipments/update',
    async ({ officeEquipmentData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await officeEquipmentAPIService.updateOfficeEquipment(officeEquipmentData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all office equipments
export const getOfficeEquipments = createAsyncThunk('officeequipments/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await officeEquipmentAPIService.getOfficeEquipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const officeEquipmentSlice = createSlice({
    name: 'officeEquipment',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create office equipment
            .addCase(createOfficeEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createOfficeEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.officeEquipment = action.payload;
                state.message = 'Office equipment created successfully';
            })
            .addCase(createOfficeEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update office equipment
            .addCase(updateOfficeEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateOfficeEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.officeEquipment = action.payload;
                state.message = 'Office equipment updated successfully';
            })
            .addCase(updateOfficeEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all offices
            .addCase(getOfficeEquipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOfficeEquipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.officeEquipments = action.payload.results;
                state.message = "";
            })
            .addCase(getOfficeEquipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = officeEquipmentSlice.actions;
export default officeEquipmentSlice.reducer;