import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetMovementByPersonReportAPIService from './personMovementAPIService';


const initialState = {
    assetMovementByPersonReports: [],
    assetMovementByPersonReport: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};


// get asset movement by person report
export const getAssetMovementByPersonReport = createAsyncThunk(
    'report/assetMovementByPersonReport',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetMovementByPersonReportAPIService.getAssetMovementByPersonReport(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            throw new Error(message);
        }
    }
);

// create slice
const assetMovementByPersonReportSlice = createSlice({
    name: 'assetMovementByPersonReport',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // get asset movement by person report
            .addCase(getAssetMovementByPersonReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetMovementByPersonReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetMovementByPersonReports = action.payload;
                state.message = 'Asset movement by person report loaded successfully';
            })
            .addCase(getAssetMovementByPersonReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetMovementByPersonReportSlice.actions;
export default assetMovementByPersonReportSlice.reducer;