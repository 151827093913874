import React, { useState, useEffect } from "react";

const DjangoAdminPanel = () => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const fetchHtml = async () => {
      const response = await fetch("/admin");
      const text = await response.text();
      setHtml(text);
    };
    fetchHtml();
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default DjangoAdminPanel;
