import React, { useEffect } from "react";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { activate, reset } from '../../features/auth/authSlice';
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const AccountActivation = () => {
    const themes = useTheme();
    const { uid, token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const colors = tokens(themes.palette.mode);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const { isSuccess, isError, message } = useSelector((state) => state.auth);

    // function for when the activation form submit button is clicked
    const submitHandler = () => {
        // send activation form data to backend
        dispatch(activate({ uid, token }));
        toast.success("Your account has been activated. Please login.");

    };

    // if user is activated, set page type to login
    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
        if (isSuccess) {
            navigate("/");
        }
        dispatch(reset());
    }, [isSuccess, isError, dispatch, message, navigate]
    );
    
    return (
        <>
            <Box
                width="100%"
                backgroundColor="transparent"
                p="1rem 6%"
                mt="1rem"
                mb="0rem"
                textAlign="center"
            >
                {/* <img src={require('../../images/logo/arc3WhiteFullSm.png')} alt='ARC+' /> */}
                <img src={require('../../images/logo/logo-4-full.png')} alt='ARC+' />
            </Box>
            <Box
                width={isNonMobileScreens ? "50%" : "93%"}
                p="1rem 2rem 1rem 2rem"
                m="1rem auto"
                textAlign="center"
                borderRadius="1.5rem"
                backgroundColor={colors.primary[400]}
            >
                <IconButton>
                    <CheckCircleOutlineRoundedIcon
                        sx={{
                            fontSize: "100px",
                            color: colors.greenAccent[500]
                        }}
                    />
                </IconButton>
                <Typography
                    variant="h3"
                    color={colors.greenAccent[500]}
                    fontWeight="bold"
                    sx={{ mb: "2rem" }}
                >
                    Activate Your Account to Continue
                </Typography>
                <Box>
                    <Button
                        fullWidth
                        type="submit"
                        onClick={submitHandler}
                        sx={{
                            m: "1rem 0",
                            p: "1rem",
                            backgroundColor: colors.greenAccent[800],
                            color: colors.grey[100],
                            "&:hover": { color: colors.greenAccent[600] },
                            fontSize: "1.2rem",
                        }}
                    >
                        ACTIVATE
                    </Button>
            </Box>
            </Box>
        </>
    );
};

export default AccountActivation;