import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import branchAPIService from "./branchAPIService";


const initialState = {
    branches: [],
    branch: {},
    regionbranches: [],
    regionbranch: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create branch
export const createBranch = createAsyncThunk('branches/create',
    async (branchData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await branchAPIService.createBranch(branchData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update branch
export const updateBranch = createAsyncThunk('branches/update',
    async (branchData, pkid, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await branchAPIService.updateBranch(branchData, pkid, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all branchs
export const getBranches = createAsyncThunk('branches/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await branchAPIService.getBranches(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get branches by region
export const getRegionBranches = createAsyncThunk('branches/getByRegion',
    async (region, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await branchAPIService.getRegionBranches(region, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create branch
            .addCase(createBranch.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createBranch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.branch = action.payload;
                state.message = 'Branch created successfully';
            })
            .addCase(createBranch.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update branch
            .addCase(updateBranch.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateBranch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.branch = action.payload;
                state.message = 'Branch updated successfully';
            })
            .addCase(updateBranch.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all branches
            .addCase(getBranches.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBranches.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.branches = action.payload;
                state.message = "";
            })
            .addCase(getBranches.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get branches by region
            .addCase(getRegionBranches.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRegionBranches.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.regionbranches = action.payload;
                state.message = "";
            })
            .addCase(getRegionBranches.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = branchSlice.actions;

export default branchSlice.reducer;