import axios from "axios";

const CREATE_MACHINERY_URL = "/api/v1/register/machinery-asset-create/";
const UPDATE_MACHINERY_URL = "/api/v1/register/machinery-asset-update/barcode:barcode/";
const GET_MACHINERIES_URL = "/api/v1/register/machinery-register/";

//get machinery equipments
const getMachineryEquipments = async (token) => {
    const response = await axios.get(GET_MACHINERIES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create machinery
const createMachineryEquipment = async (machineryData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_MACHINERY_URL, machineryData, config);
    return response.data;
};


//update machinery
const updateMachineryEquipment = async (machineryData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_MACHINERY_URL.replace("barcode", barcode), machineryData, config);
    return response.data;
};

const machineryAPIService = { createMachineryEquipment, updateMachineryEquipment, getMachineryEquipments };

export default machineryAPIService;