import axios from "axios";

const CREATE_DEPARTMENT_URL = "/api/v1/entities/departments/";
const GET_DEPARTMENTS_URL = "/api/v1/entities/departments/";

//get departments
const getDepartments = async (token) => {
    const response = await axios.get(GET_DEPARTMENTS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create department
const createDepartment = async (departmentData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_DEPARTMENT_URL, departmentData, config);
    return response.data;
};

const departmentAPIService = { createDepartment, getDepartments };

export default departmentAPIService;