import React, { useState } from "react";
import { tokens } from "../../../theme";
import { Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TopAppBar from "../../global/TopAppBar";

// Pages for each movement tab
import CapturedAssetsReport from "../newly-captured/newly-captured-report_mrt";
import AssetMovementByLocationReport from "../movement-location/movement-location-report_mrt";
import AssetMovementByPersonReport from "../movement-person/movement-person-report_mrt";
import AssetVerificationReport from "../verification/verification-report_mrt";

// Icons for each movement tab
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `movement-tab-${index}`,
        "aria-controls": `movement-tabpanel-${index}`,
    };
}

const AssetsReports = () => {
    const [setIsSidebar] = useState(true);
    const [value, setValue] = React.useState(0);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="app">
            <main className="content">
                <TopAppBar setIsSidebar={setIsSidebar} />
                <Box m="20px">
                    <Box sx={{ width: '100%' }}>
                        <Box mb="0.1rem">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                aria-label="Locations Tabs"
                                TabIndicatorProps={{ style: { background: colors.blueAccent[600] } }}
                                textColor="inherit"
                            >
                                <Tab icon={<QrCodeScannerOutlinedIcon />} iconPosition="start" label="Newly Captured Assets" {...a11yProps(0)} />
                                <Tab icon={<LocalShippingOutlinedIcon />} iconPosition="start" label="Assets Movement By Location" {...a11yProps(1)} />
                                <Tab icon={<TransferWithinAStationOutlinedIcon />} iconPosition="start" label="Asset Movement By Person" {...a11yProps(2)} />
                                <Tab icon={<VerifiedOutlinedIcon />} iconPosition="start" label="Assets Verification" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <CapturedAssetsReport />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AssetMovementByLocationReport />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AssetMovementByPersonReport />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <AssetVerificationReport />
                        </TabPanel>
                    </Box>
                </Box>
            </main>
        </div>
    );
};

export default AssetsReports;