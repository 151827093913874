import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import intangibleAssetAPIService from "./intangibleAPIService";


const initialState = {
    intangibleassets: [],
    intangibleasset: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create intangible asset
export const createIntangibleAsset = createAsyncThunk('intangibles/create',
    async (intangibleData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await intangibleAssetAPIService.createIntangibleAsset(intangibleData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update intangible asset
export const updateIntangibleAsset = createAsyncThunk('intangibles/update',
    async ({ intangibleData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await intangibleAssetAPIService.updateIntangibleAsset(intangibleData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all intangible assets
export const getIntangibleAssets = createAsyncThunk('intangibles/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await intangibleAssetAPIService.getIntangibleAssets(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const intangibleAssetSlice = createSlice({
    name: 'intangibleasset',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create intangible
            .addCase(createIntangibleAsset.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createIntangibleAsset.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.intangibleasset = action.payload;
                state.message = 'Intangible asset created successfully';
            })
            .addCase(createIntangibleAsset.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all intangible assets
            .addCase(getIntangibleAssets.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getIntangibleAssets.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.intangibleassets = action.payload.results;
                state.message = "";
            })
            .addCase(getIntangibleAssets.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = intangibleAssetSlice.actions;
export default intangibleAssetSlice.reducer;