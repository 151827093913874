import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import roomAPIService from "./roomAPIService";


const initialState = {
    rooms: [],
    room: {},
    branchrooms: [],
    branchroom: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create room
export const createRoom = createAsyncThunk('rooms/create',
    async (roomData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await roomAPIService.createRoom(roomData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all rooms
export const getRooms = createAsyncThunk('rooms/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await roomAPIService.getRooms(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get rooms by branch
export const getBranchRooms = createAsyncThunk('rooms/getByBranch',
    async (branch, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await roomAPIService.getBranchRooms(branch, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create room
            .addCase(createRoom.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createRoom.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.room = action.payload;
                state.message = 'Room created successfully';
            })
            .addCase(createRoom.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all rooms
            .addCase(getRooms.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRooms.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.rooms = action.payload;
                state.message = "";
            })
            .addCase(getRooms.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get rooms by branch
            .addCase(getBranchRooms.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBranchRooms.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.branchrooms = action.payload;
                state.message = "";
            })
            .addCase(getBranchRooms.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = roomSlice.actions;
export default roomSlice.reducer;