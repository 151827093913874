import axiox from 'axios';

const GET_USERS_URL = '/api/v1/profile/all-users/';
const GET_USER_URL = '/api/v1/profile/my-profile/';

// get all users
const getUsers = async (token) => {
    const response = await axiox.get(GET_USERS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
};

// get user
const getUser = async (id, token) => {
    const response = await axiox.get(GET_USER_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
};

const userAPIService = { getUsers, getUser };

export default userAPIService;