import axios from "axios";

const CREATE_SPECIALIZED_URL = "/api/v1/register/specialized-asset-create/";
const UPDATE_SPECIALIZED_URL = "/api/v1/register/specialized-asset-update/barcode:barcode/";
const GET_SPECIALIZEDS_URL = "/api/v1/register/specialized-register/";

//get specialized buildings
const getSpecializedBuildings = async (token) => {
    const response = await axios.get(GET_SPECIALIZEDS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create specialized Building
const createSpecializedBuilding = async (specializedBuildingData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_SPECIALIZED_URL, specializedBuildingData, config);
    return response.data;
};


//update specialized Building
const updateSpecializedBuilding = async (specializedBuildingData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_SPECIALIZED_URL.replace("barcode", barcode), specializedBuildingData, config);
    return response.data;
};

const specializedBuildingAPIService = { createSpecializedBuilding, updateSpecializedBuilding, getSpecializedBuildings };

export default specializedBuildingAPIService;