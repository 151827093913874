import axios from "axios";

const GET_ASSET_MOVEMENT_BY_PERSON_REPORT = "/api/v1/reports/asset-movement-by-person/";

// get asset movement by person report
const getAssetMovementByPersonReport = async (token) => {
    const response = await axios.get(GET_ASSET_MOVEMENT_BY_PERSON_REPORT, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

const assetMovementByPersonReportAPIService = { getAssetMovementByPersonReport };
export default assetMovementByPersonReportAPIService;