import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import departmentAPIService from "./departmentAPIService";


const initialState = {
    departments: [],
    department: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create department
export const createDepartment = createAsyncThunk('departments/create',
    async (departmentData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await departmentAPIService.createDepartment(departmentData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];
            
            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all departments
export const getDepartments = createAsyncThunk('departments/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await departmentAPIService.getDepartments(token);
        } catch (error) {
            const message = 
            error.message ||
            error.toString() ||
            error.response?.data?.name[0];
        
        // return thunkAPI.rejectWithValue({ error: message });
        throw new Error(message);
        }
    }
);

export const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create department
            .addCase(createDepartment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createDepartment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.department = action.payload;
                state.message = 'Department created successfully';
            })
            .addCase(createDepartment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all departments
            .addCase(getDepartments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDepartments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.departments = action.payload;
                state.message = "";
            })
            .addCase(getDepartments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = departmentSlice.actions;
export default departmentSlice.reducer;