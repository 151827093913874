import axios from "axios";

const CREATE_REGION_URL = "/api/v1/entities/regions/";
const GET_REGIONS_URL = "/api/v1/entities/regions/";

//get regions
const getRegions = async (token) => {
    const response = await axios.get(GET_REGIONS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create region
const createRegion = async (regionData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_REGION_URL, regionData, config);
    return response.data;
};

const regionAPIService = { createRegion, getRegions };

export default regionAPIService;