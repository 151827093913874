import React from 'react';
import { Box, } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const RegionDetailView = (rowData) => {
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const data = rowData.rowData;

    return (
        <Box
            m="5px"
            sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
        >
            <Box>
                <Box
                    variant="h3"
                    sx={{ color: "text.primary" }}
                >
                    Region - <h4>{data.name}</h4> refference code - <h4>{data.ref_code}</h4>
                </Box>
                <Box
                    variant="h3"
                    sx={{ color: "text.primary" }}
                >
                    Full Description: <h4>{data.description}</h4>
                </Box>
                {/* <Box
                    component="img"
                    src={regionData.image}
                    alt={regionData.name}
                    sx={{ width: "100%", height: "auto", borderRadius: "10px" }}
                /> */}
            </Box>

        </Box>
    );
};

export default RegionDetailView;