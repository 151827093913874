import React, { useEffect } from "react";
import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOtlinedIcon from "@mui/icons-material/HomeOutlined";
import MediationIcon from '@mui/icons-material/Mediation';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import InventoryIcon from '@mui/icons-material/Inventory';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CategoryIcon from '@mui/icons-material/Category';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RuleIcon from '@mui/icons-material/Rule';
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import { useNavigate  } from 'react-router-dom';
import { getUserProfile, logout, reset } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';


const Item = ({ title, icon, to, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            onClick={() => setSelected(title)}
            icon={icon}
            style={{
                color: colors.grey[100],
            }}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};


const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // Set the sidebar to collapsed or not
    const [isCollapsed, setIsCollapsed] = useState(false);
    // Set the selected menu item to active
    const [selected, setSelected] = useState("Darshboard");

    // authentication
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Get user profile
    const { profile, isLoading } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getUserProfile())
    });

    if (isLoading) {
        return <ProgressCircle />;
    }

    // const userProfile = [];
    // const profileObject = profile;
    // const profileData = Object(profileObject || {});
    // for (const prof in profileData) {
    //     userProfile.push(profileData[prof]);
    // }


    const logoutHandler = async () => {
        await dispatch(logout());
        await dispatch(reset());
        navigate("/login");
    };

    return (
        <Box
            display="flex"
            sx={{
            "& .pro-sidebar-inner": {
                background: `${colors.primary[400]} !important`,
            },
            "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
                padding: "5px 35px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
                color: "#868dfb !important",
            },
            "& .pro-menu-item.active": {
                color: "#6870fa !important",
            },
        }}
        >
            <ProSidebar collapsed={isCollapsed} breakPoint="sm" transitionduration={800}>
                <Menu iconShape="square">
                    {/* Logo and Menu Icon */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px">
                                <Box textAlign="center">
                                    <Typography
                                        variant="h2"
                                        color={colors.grey[100]}
                                    >
                                        ArcPlus+
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        color={colors.greenAccent[600]}
                                    >
                                        Manage & Track
                                    </Typography>
                                </Box>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {/* User */}
                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`}
                                    style={{ cursor: "pointer", borderRadius: "50%" }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {profile}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={colors.greenAccent[500]}
                                >
                                    Fixed Assets Manager
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOtlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {/* Register Menu */}
                        <SubMenu
                            icon={<InventoryIcon />}
                            title="Register"
                        >
                            <Item
                                title="Add Asset" 
                                to="/users"
                                icon={<PostAddIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Assets List" 
                                to="/register"
                                icon={<ReceiptLongIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Assets Verification" 
                                to="/invoices"
                                icon={<RuleIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                        {/* Entities Menu */}
                        <SubMenu
                            icon={<MediationIcon />}
                            title="Entities"
                        >
                            <Item
                                title="Asset Categories" 
                                to="/entities/categories"
                                icon={<CategoryIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="SubCategories" 
                                to="/form"
                                icon={<DynamicFeedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Asset Types" 
                                to="/form"
                                icon={<MergeTypeIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Asset Models" 
                                to="/form"
                                icon={<ModelTrainingIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>

                        {/* Tracking Menu */}
                        <SubMenu
                            icon={<TrackChangesOutlinedIcon />}
                            title="Tracking"
                        >
                            <Item
                                title="Departments" 
                                to="/form"
                                icon={<EngineeringOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="CostCenters" 
                                to="/form"
                                icon={<CurrencyExchangeOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Locations" 
                                to="/"
                                icon={<PinDropIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="People" 
                                to="/calendar"
                                icon={<ReduceCapacityOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Suppliers" 
                                to="/faq"
                                icon={<EmojiTransportationOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>

                        {/* Reports Menu */}
                        <SubMenu
                            icon={<AssessmentOutlinedIcon />}
                            title="Reports"
                        >
                            <Item
                                title="Bar Chart" 
                                to="/bar"
                                icon={<BarChartOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Pie Chart" 
                                to="/pie"
                                icon={<PieChartOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Line Chart" 
                                to="/line"
                                icon={<TimelineOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Locations" 
                                to="/geography"
                                icon={<MapOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                    </Box>
                    <Box mt="25px" ml="60px" title="Logout">
                        <IconButton onClick={logoutHandler}>
                            <LogoutOutlinedIcon sx={{ fontSize: "30px" }} />
                        </IconButton>
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;