import React from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../../components/Header";
import PieChart from "../../../components/PieChart";
import { tokens } from "../../../theme";
import { Box, Button, useTheme } from "@mui/material";

const Pie = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Asset Conditions" subtitle="Summary of Assets by Condition" />
                <Box>
                    <Button
                        sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Report
                    </Button>
                </Box>
            </Box>
            <Box height="75vh">
                <PieChart />
            </Box>
        </Box>

    );
};

export default Pie;