import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
// Main Menu items and submenus
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

// Other imports
import { useContext } from 'react';
import { useTheme } from '@mui/material';
import { ColorModeContext, tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';


const TopAppBar = () => {
  // access logged in user
  const user = useSelector((state) => state.auth.user);
  // console.log(user);
  // provide access to the themes
  const theme = useTheme();
  // provide access to the color mode and tokens
  const colors = tokens(theme.palette.mode);
  // provide access to the color mode context
  const colorMode = useContext(ColorModeContext);

  // authentication
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await dispatch(logout());
    await dispatch(reset());
    navigate("/");
  };


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  // Main menu items styling
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  // Mobile menu handlers
  // Register mobile Menu event handlers
  const [registerMobileAnchorEl, setRegisterMobileAnchorEl] = React.useState(null);
  const registerMobileOpen = Boolean(registerMobileAnchorEl);
  const handleRegisterMobileClick = (event) => {
    setRegisterMobileAnchorEl(event.currentTarget);
  };
  const handleRegisterMobileClose = () => {
    setRegisterMobileAnchorEl(null);
  };

  // Entities mobile menu event handlers
  const [entitiesMobileAnchorEl, setEntitiesMobileAnchorEl] = React.useState(null);
  const entitiesMobileOpen = Boolean(entitiesMobileAnchorEl);
  const handleEntitiesMobileClick = (event) => {
    setEntitiesMobileAnchorEl(event.currentTarget);
  };
  const handleEntitiesMobileClose = () => {
    setEntitiesMobileAnchorEl(null);
  };

  // Tracking mobile menu event handlers
  // const [setTrackingMobileAnchorEl] = React.useState(null);
  // excluded from useState above - trackingMobileAnchorEl
  // const trackingMobileOpen = Boolean(trackingMobileAnchorEl);
  // const handleTrackingMobileClick = (event) => {
  //   setTrackingMobileAnchorEl(event.currentTarget);
  // };
  // const handleTrackingMobileClose = () => {
  //   setTrackingMobileAnchorEl(null);
  // };

  // Finance / Depreciation mobile menu event handlers
  const [depreciationMobileAnchorEl, setDepreciationMobileAnchorEl] = React.useState(null);
  const depreciationMobileOpen = Boolean(depreciationMobileAnchorEl);
  const handleDepreciationMobileClick = (event) => {
    setDepreciationMobileAnchorEl(event.currentTarget);
  };
  const handleDepreciationMobileClose = () => {
    setDepreciationMobileAnchorEl(null);
  };

  // Reports mobile menu event handlers
  const [reportsMobileAnchorEl, setReportsMobileAnchorEl] = React.useState(null);
  const reportsMobileOpen = Boolean(reportsMobileAnchorEl);
  const handleReportsMobileClick = (event) => {
    setReportsMobileAnchorEl(event.currentTarget);
  };
  const handleReportsMobileClose = () => {
    setReportsMobileAnchorEl(null);
  };


  // Main Menu handlers
  // Register main menu event handlers
  const [registerMainAnchorEl, setRegisterMainAnchorEl] = React.useState(null);
  const registerMainOpen = Boolean(registerMainAnchorEl);
  const handleRegisterMainClick = (event) => {
    setRegisterMainAnchorEl(event.currentTarget);
  };
  const handleRegisterMainClose = () => {
    setRegisterMainAnchorEl(null);
  };

  // Entities main Menu event handlers
  const [entitiesMainAnchorEl, setEntitiesMainAnchorEl] = React.useState(null);
  const entitiesMainOpen = Boolean(entitiesMainAnchorEl);
  const handleEntitiesMainClick = (event) => {
    setEntitiesMainAnchorEl(event.currentTarget);
  };
  const handleEntitiesMainClose = () => {
    setEntitiesMainAnchorEl(null);
  };

  // Tracking main menu event handlers
  // const [setTrackingMainAnchorEl] = React.useState(null);
  // excluded from useState above - trackingMainAnchorEl
  // const trackingMainOpen = Boolean(trackingMainAnchorEl);
  // const handleTrackingMainClick = (event) => {
  //   setTrackingMainAnchorEl(event.currentTarget);
  // };
  // const handleTrackingMainClose = () => {
  //   setTrackingMainAnchorEl(null);
  // };

  // Finance / Depreciation main menu event handlers
  const [depreciationMainAnchorEl, setDepreciationMainAnchorEl] = React.useState(null);
  const depreciationMainOpen = Boolean(depreciationMainAnchorEl);
  const handleDepreciationMainClick = (event) => {
    setDepreciationMainAnchorEl(event.currentTarget);
  };
  const handleDepreciationMainClose = () => {
    setDepreciationMainAnchorEl(null);
  };

  // Reports main menu event handlers
  const [reportsMainAnchorEl, setReportsMainAnchorEl] = React.useState(null);
  const reportsMainOpen = Boolean(reportsMainAnchorEl);
  const handleReportsMainClick = (event) => {
    setReportsMainAnchorEl(event.currentTarget);
  };
  const handleReportsMainClose = () => {
    setReportsMainAnchorEl(null);
  };

  return (
    <Box sx={{ "& .MuiAppBar-root": { background: `${colors.primary[400]} !important`, } }}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Start of Main logo */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <a href="/dashboard">
                <img src={require('../../images/logo/arc3BlueFullSm.png')} alt='ARC+' />
              </a>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                // ml: 1,
                display: { xs: 'none', md: 'flex' },
                // fontFamily: 'monospace',
                fontWeight: 700,
                fontSize: '1.5rem',
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
            </Typography>
            {/* End of Main logo */}

            {/* Start of Mobile Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              {/* Start Mobile Menu */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >

                {/* Dashboard mobile Menu */}
                <Button
                  sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                  id="register-button-mobile"
                  aria-controls={registerMobileOpen ? 'register-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={registerMobileOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={() => navigate("/dashboard")}
                // endIcon={<KeyboardArrowDownIcon />}
                >
                  Dashboard
                </Button>

                {/* Register mobile Menu */}
                <Button
                  sx={{ my: 2, fontSize: '0.9rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                  id="register-button-mobile"
                  aria-controls={registerMobileOpen ? 'register-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={registerMobileOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleRegisterMobileClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Register
                </Button>
                <StyledMenu
                  id="register-menu-mobile"
                  MenuListProps={{
                    'aria-labelledby': 'register-button-mobile',
                  }}
                  anchorEl={registerMobileAnchorEl}
                  open={registerMobileOpen}
                  onClose={handleRegisterMobileClose}
                >
                  <MenuItem onClick={() => navigate("/register")}>
                    <FormatListNumberedOutlinedIcon />
                    Asset Register
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <ShoppingCartCheckoutOutlinedIcon />
                    Checkin / Checkout
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <TransferWithinAStationOutlinedIcon />
                    Issue / Return
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <LocalShippingOutlinedIcon />
                    Move Assets
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <VerifiedOutlinedIcon />
                    Verify Assets
                  </MenuItem>
                </StyledMenu>

                {/* Entities mobile Menu */}
                <Button
                  sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                  id="entities-button-mobile"
                  aria-controls={entitiesMobileOpen ? 'entities-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={entitiesMobileOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleEntitiesMobileClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Entities
                </Button>
                <StyledMenu
                  id="entities-menu-mobile"
                  MenuListProps={{
                    'aria-labelledby': 'entities-button-mobile',
                  }}
                  anchorEl={entitiesMobileAnchorEl}
                  open={entitiesMobileOpen}
                  onClose={handleEntitiesMobileClose}
                >
                  <MenuItem onClick={() => navigate("/entities/categorizations")}>
                    <CategoryOutlinedIcon />
                    Categorizations
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/entities/locations")}>
                    <LocationOnOutlinedIcon />
                    Locations
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <PeopleAltOutlinedIcon />
                    People
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <CorporateFareOutlinedIcon />
                    Departments
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <HubOutlinedIcon />
                    Projects
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <VolunteerActivismOutlinedIcon />
                    Sponsors
                  </MenuItem>
                </StyledMenu>

                {/* Depreciation mobile Menu */}
                <Button
                  sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                  id="tracking-button-mobile"
                  aria-controls={depreciationMobileOpen ? 'depreciation-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={depreciationMobileOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleDepreciationMobileClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Depreciation
                </Button>
                <StyledMenu
                  id="depreciation-menu-mobile"
                  MenuListProps={{
                    'aria-labelledby': 'depreciation-button-mobile',
                  }}
                  anchorEl={depreciationMobileAnchorEl}
                  open={depreciationMobileOpen}
                  onClose={handleDepreciationMobileClose}
                >
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <AssuredWorkloadOutlinedIcon />
                    Depreciation
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <MonetizationOnOutlinedIcon />
                    Valuation
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <PriceChangeOutlinedIcon />
                    Depreciation Sets
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <SavingsOutlinedIcon />
                    Insurance Valution
                  </MenuItem>
                </StyledMenu>

                {/* Reports mobile Menu */}
                <Button
                  sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                  id="reports-button-mobile"
                  aria-controls={reportsMobileOpen ? 'reports-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={reportsMobileOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleReportsMobileClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Reports
                </Button>
                <StyledMenu
                  id="reports-menu-mobile"
                  MenuListProps={{
                    'aria-labelledby': 'reports-button-mobile',
                  }}
                  anchorEl={reportsMobileAnchorEl}
                  open={reportsMobileOpen}
                  onClose={handleReportsMobileClose}
                >
                  <MenuItem onClick={() => navigate("/reports/assets")}>
                    <AssessmentOutlinedIcon />
                    Assets Reports
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/upgrade")}>
                    <QueryStatsOutlinedIcon />
                    Depreciation
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                </StyledMenu>
              </Menu>
            </Box>
            {/* End of Mobile Menu */}

            {/* Start of Mobile Logo */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: '3rem' }}>
              <a href="/dashboard">
                <img src={require('../../images/logo/arc3BlueFullSm.png')} alt='ARC+' />
              </a>
            </Box>
            {/* End of Mobile Logo */}

            {/* Start of Main Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

              {/* Dashboard main menu */}
              <Button
                sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                id="register-button-main"
                aria-controls={registerMainOpen ? 'register-menu-main' : undefined}
                aria-haspopup="true"
                aria-expanded={registerMainOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={() => navigate("/dashboard")}
              // endIcon={<KeyboardArrowDownIcon />}
              >
                Dashboard
              </Button>

              {/* Register main menu */}
              <Button
                sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                id="register-button-main"
                aria-controls={registerMainOpen ? 'register-menu-main' : undefined}
                aria-haspopup="true"
                aria-expanded={registerMainOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleRegisterMainClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Register
              </Button>
              <StyledMenu
                id="register-menu-main"
                MenuListProps={{
                  'aria-labelledby': 'register-button-main',
                }}
                anchorEl={registerMainAnchorEl}
                open={registerMainOpen}
                onClose={handleRegisterMainClose}
              >
                <MenuItem onClick={() => navigate("/register")}>
                  <FormatListNumberedOutlinedIcon />
                  Asset Register
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <ShoppingCartCheckoutOutlinedIcon />
                  Checkin / Checkout
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <TransferWithinAStationOutlinedIcon />
                  Issue / Return
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <LocalShippingOutlinedIcon />
                  Move Assets
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <VerifiedOutlinedIcon />
                  Verify Assets
                </MenuItem>
              </StyledMenu>

              {/* Entities main menu */}
              <Button
                sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                id="entities-button-main"
                aria-controls={entitiesMainOpen ? 'entities-menu-main' : undefined}
                aria-haspopup="true"
                aria-expanded={entitiesMainOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleEntitiesMainClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Entities
              </Button>
              <StyledMenu
                id="entities-menu-main"
                MenuListProps={{
                  'aria-labelledby': 'entities-button-main',
                }}
                anchorEl={entitiesMainAnchorEl}
                open={entitiesMainOpen}
                onClose={handleEntitiesMainClose}
              >
                <MenuItem onClick={() => navigate("/entities/categorizations")}>
                  <CategoryOutlinedIcon />
                  Categorizations
                </MenuItem>
                <MenuItem onClick={() => navigate("/entities/locations")}>
                  <LocationOnOutlinedIcon />
                  Locations
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <PeopleAltOutlinedIcon />
                  People
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <CorporateFareOutlinedIcon />
                  Departments
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <HubOutlinedIcon />
                  Projects
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <VolunteerActivismOutlinedIcon />
                  Sponsors
                </MenuItem>
              </StyledMenu>

              {/* Depreciation main Menu */}
              <Button
                sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                id="depreciation-button-main"
                aria-controls={depreciationMainOpen ? 'depreciation-menu-main' : undefined}
                aria-haspopup="true"
                aria-expanded={depreciationMainOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleDepreciationMainClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Depreciation
              </Button>
              <StyledMenu
                id="depreciation-menu-main"
                MenuListProps={{
                  'aria-labelledby': 'depreciation-button-main',
                }}
                anchorEl={depreciationMainAnchorEl}
                open={depreciationMainOpen}
                onClose={handleDepreciationMainClose}
              >
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <AssuredWorkloadOutlinedIcon />
                  Depreciation
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <MonetizationOnOutlinedIcon />
                  Valuation
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <PriceChangeOutlinedIcon />
                  Depreciation Sets
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <SavingsOutlinedIcon />
                  Insurance Valuation
                </MenuItem>
              </StyledMenu>

              {/* Reports main Menu */}
              <Button
                sx={{ my: 2, fontSize: '0.8rem', color: 'white', backgroundColor: 'transparent', display: 'flex' }}
                id="reports-button-main"
                aria-controls={reportsMainOpen ? 'reports-menu-main' : undefined}
                aria-haspopup="true"
                aria-expanded={reportsMainOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleReportsMainClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Reports
              </Button>
              <StyledMenu
                id="reports-menu-main"
                MenuListProps={{
                  'aria-labelledby': 'reports-button-main',
                }}
                anchorEl={reportsMainAnchorEl}
                open={reportsMainOpen}
                onClose={handleReportsMainClose}
              >
                <MenuItem onClick={() => navigate("/reports/assets")}>
                  <AssessmentOutlinedIcon />
                  Assets Reports
                </MenuItem>
                <MenuItem onClick={() => navigate("/upgrade")}>
                  <QueryStatsOutlinedIcon />
                  Depreciation Reports
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
              </StyledMenu>
            </Box>
            {/* End of Main Menu */}

            {/* Top Right Menu Icons */}
            <Box sx={{ flexGrow: 0, mr: 1.5 }}>
              <Tooltip title="Dark / Light Mode">
                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === 'dark' ? (
                    <Brightness4OutlinedIcon sx={{ fontSize: "1.2em" }} />
                  ) : (
                    <Brightness4OutlinedIcon sx={{ fontSize: "1.1em" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Help & Tips">
                <IconButton onClick={() => navigate("/faq")}>
                  <HelpOutlineOutlinedIcon sx={{ fontSize: "1.2em" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Admin Panel">
                <IconButton onClick={() => navigate("/arcadmin")}>
                  <AdminPanelSettingsOutlinedIcon sx={{ fontSize: "1.2em" }} />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={user ? user.full_name : "User Menu"}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                  <ManageAccountsOutlinedIcon sx={{ fontSize: "1.3em" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={logoutHandler}>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>
                <MenuItem onClick={logoutHandler}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
export default TopAppBar;