import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import regionAPIService from "./regionAPIService";


const initialState = {
    regions: [],
    region: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create region
export const createRegion = createAsyncThunk('regions/create',
    async (regionData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await regionAPIService.createRegion(regionData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];
            
            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all regions
export const getRegions = createAsyncThunk('regions/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await regionAPIService.getRegions(token);
        } catch (error) {
            const message = 
            error.message ||
            error.toString() ||
            error.response?.data?.name[0];
        
        // return thunkAPI.rejectWithValue({ error: message });
        throw new Error(message);
        }
    }
);

export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create region
            .addCase(createRegion.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createRegion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.region = action.payload;
                state.message = 'Region created successfully';
            })
            .addCase(createRegion.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all regions
            .addCase(getRegions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRegions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.regions = action.payload;
                state.message = "";
            })
            .addCase(getRegions.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = regionSlice.actions;
export default regionSlice.reducer;