import axios from "axios";

const REGISTRATION_URL = "/api/v1/auth/users/"
const LOGIN_URL = "/api/v1/auth/jwt/create/"
const ACTIVATE_URL = "/api/v1/auth/users/activation/"
const PROFILE_URL = "/api/v1/profile/my-profile/"

// User Registration
const registration = async (userData) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        },
    };

    const response = await axios.post(REGISTRATION_URL, userData, config);
    return response.data;
};


// Login user
const login = async (userData) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(LOGIN_URL, userData, config)
    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data))
    }
    return response.data;
};


// Activate user
const activate = async (userData) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(ACTIVATE_URL, userData, config);
    return response.data;
};

// Get user profile
const getUserProfile = async () => {
    const token = JSON.parse(localStorage.getItem("user")).access;
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    const response = await axios.get(PROFILE_URL, config);
    return response.data
};


// Logout user
const logout = () => localStorage.removeItem("user");


// Export auth services
const authService = { registration, login, activate, getUserProfile, logout };

export default authService;