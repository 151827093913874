import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetModelAPIService from "./modelAPIService";


const initialState = {
    assetmodels: [],
    assetmodel: {},
    makeassetmodels: [],
    makeassetmodel: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create asset model
export const createAssetModel = createAsyncThunk('assetmodel/create',
    async (assetModelData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetModelAPIService.createAssetModel(assetModelData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all asset models
export const getAssetModels = createAsyncThunk('assetmodel/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetModelAPIService.getAssetModels(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get asset models by make
export const getMakeAssetModels = createAsyncThunk('assetmodel/getByMake',
    async (asset_make, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetModelAPIService.getMakeAssetModels(asset_make, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const assetModelSlice = createSlice({
    name: 'assetmodel',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create asset model
            .addCase(createAssetModel.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createAssetModel.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.category = action.payload;
                state.message = 'Asset Model created successfully';
            })
            .addCase(createAssetModel.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all asset models
            .addCase(getAssetModels.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetModels.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetmodels = action.payload;
                state.message = "";
            })
            .addCase(getAssetModels.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get asset models by type
            .addCase(getMakeAssetModels.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMakeAssetModels.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.makeassetmodels = action.payload;
                state.message = "";
            })
            .addCase(getMakeAssetModels.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetModelSlice.actions;
export default assetModelSlice.reducer;