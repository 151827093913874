import axios from "axios";

const CREATE_ROOM_URL = "/api/v1/entities/rooms/";
const GET_ROOMS_URL = "/api/v1/entities/rooms/";
const GET_BRANCH_ROOMS_URL = "/api/v1/entities/roomsbybranch/";

//get rooms
const getRooms = async (token) => {
    const response = await axios.get(GET_ROOMS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get rooms by branch
const getBranchRooms = async (branch, token) => {
    const response = await axios.get(`${GET_BRANCH_ROOMS_URL}?branch=${branch}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create room
const createRoom = async (roomData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ROOM_URL, roomData, config);
    return response.data;
};

const roomAPIService = { createRoom, getRooms, getBranchRooms };

export default roomAPIService;