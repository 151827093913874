import axios from "axios";

const CREATE_TRANSPORT_URL = "/api/v1/register/transport-asset-create/";
const UPDATE_TRANSPORT_URL = "/api/v1/register/transport-asset-update/barcode:barcode/";
const GET_TRANSPORTS_URL = "/api/v1/register/transport-register/";

//get transport equipments
const getTransportEquipments = async (token) => {
    const response = await axios.get(GET_TRANSPORTS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create transport equipment
const createTransportEquipment = async (transportEquipmentData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_TRANSPORT_URL, transportEquipmentData, config);
    return response.data;
};


//update transport equipment
const updateTransportEquipment = async (transportEquipmentData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_TRANSPORT_URL.replace("barcode", barcode), transportEquipmentData, config);
    return response.data;
};

const transportEquipmentAPIService = { createTransportEquipment, updateTransportEquipment, getTransportEquipments };

export default transportEquipmentAPIService;