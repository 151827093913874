import axios from "axios";

const CREATE_SUBCATEGORY_URL = "/api/v1/entities/asset-subcategories/";
const GET_SUBCATEGORIES_URL = "/api/v1/entities/asset-subcategories/";
const GET_CATEGORY_SUBCATEGORIES_URL = "/api/v1/entities/asset-subcategoriesbycategory/";

//get subcategories
const getSubCategories = async (token) => {
    const response = await axios.get(GET_SUBCATEGORIES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get subcategories by category
const getCategorySubCategories = async (asset_category, token) => {
    const response = await axios.get(`${GET_CATEGORY_SUBCATEGORIES_URL}?asset_category=${asset_category}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create category
const createSubCategory = async (subcategoryData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_SUBCATEGORY_URL, subcategoryData, config);
    return response.data;
};

const subcategoryAPIService = { createSubCategory, getSubCategories, getCategorySubCategories };

export default subcategoryAPIService;