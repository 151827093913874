
export const getAssetsByCondition = (assets) => {
  const newAssets = assets.filter((asset) => asset.condition === "NEW").length;
  const goodAssets = assets.filter((asset) => asset.condition === "GOOD").length;
  const fairAssets = assets.filter((asset) => asset.condition === "FAIR").length;
  const badAssets = assets.filter((asset) => asset.condition === "BAD").length;
  const brokenAssets = assets.filter((asset) => asset.condition === "BROKEN").length;

  return [
    {
      id: "Bad",
      label: "Bad",
      value: badAssets,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "Broken",
      label: "Broken",
      value: brokenAssets,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "Fair",
      label: "Fair",
      value: fairAssets,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "Good",
      label: "Good",
      value: goodAssets,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "New",
      label: "New",
      value: newAssets,
      color: "hsl(344, 70%, 50%)",
    },
  ];
}