import axios from "axios";

const GET_CAPTURED_ASSETS_REPORTS_URL = "/api/v1/reports/newly-captured-assets/";

// get new assets capture report
const getCapturedAssetsReport = async (token) => {
    const response = await axios.get(GET_CAPTURED_ASSETS_REPORTS_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

const assetCaptureReportAPIService = { getCapturedAssetsReport };

export default assetCaptureReportAPIService;