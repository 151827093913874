import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subcategoryAPIService from "./subcategoryAPIService";


const initialState = {
    subcategories: [],
    subcategory: {},
    categorysubcategories: [],
    categorysubcategory: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create category
export const createSubCategory = createAsyncThunk('subcategories/create',
    async (subcategoryData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await subcategoryAPIService.createSubCategory(subcategoryData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all subcategories
export const getSubCategories = createAsyncThunk('subcategories/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await subcategoryAPIService.getSubCategories(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get subcategories by category
export const getCategorySubCategories = createAsyncThunk('subcategories/getByCategory',
    async (asset_category, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await subcategoryAPIService.getCategorySubCategories(asset_category, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const subcategorySlice = createSlice({
    name: 'subcategory',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create category
            .addCase(createSubCategory.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.category = action.payload;
                state.message = 'SubCategory created successfully';
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all categories
            .addCase(getSubCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subcategories = action.payload;
                state.message = "";
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get categories by region
            .addCase(getCategorySubCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategorySubCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.categorysubcategories = action.payload;
                state.message = "";
            })
            .addCase(getCategorySubCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = subcategorySlice.actions;
export default subcategorySlice.reducer;