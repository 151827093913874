import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetTypeAPIService from "./typeAPIService";


const initialState = {
    assettypes: [],
    assettype: {},
    subcategoryassettypes: [],
    subcategoryassettype: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create asset type
export const createAssetType = createAsyncThunk('assettypes/create',
    async (assetTypeData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetTypeAPIService.createAssetType(assetTypeData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all asset types
export const getAssetTypes = createAsyncThunk('assettypes/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetTypeAPIService.getAssetTypes(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

//get asset types by subcategory
export const getSubCategoryAssetTypes = createAsyncThunk('assettypes/getBySubCategory',
    async (asset_subcategory, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetTypeAPIService.getSubCategoryAssetTypes(asset_subcategory, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const assetTypeSlice = createSlice({
    name: 'assettype',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create asset type
            .addCase(createAssetType.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createAssetType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.assettype = action.payload;
                state.message = 'Asset Type created successfully';
            })
            .addCase(createAssetType.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all asset types
            .addCase(getAssetTypes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetTypes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assettypes = action.payload;
                state.message = "";
            })
            .addCase(getAssetTypes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get asset types by subcategory
            .addCase(getSubCategoryAssetTypes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCategoryAssetTypes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subcategoryassettypes = action.payload;
                state.message = "";
            })
            .addCase(getSubCategoryAssetTypes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetTypeSlice.actions;
export default assetTypeSlice.reducer;
