import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { Formik } from 'formik';
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { getBranches } from "../../../features/entities/branches/branchSlice";
import { createRoom } from "../../../features/entities/rooms/roomSlice";


const roomSchema = yup.object().shape({
    ref_code: yup.string().required("Ref_code is required"),
    slug: yup.string().required("slug is required"),
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    floor: yup.string().required("Floor is required"),
    gps_coordinates: yup.string().required("GPS Coordinates is required"),
});

const EditRoomForm = (rowData) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const { branches } = useSelector((state) => state.branches);
    const { isError, isSuccess, message } = useSelector((state) => state.floors);
    const newRoom = useSelector((state) => state.rooms.room);

    //Data from row
    const data = rowData.rowData;

    const initialvalues = {
        slug: "abcd",
        ref_code: data.ref_code || 'default_ref_code',
        name: data.name || 'default_name',
        description: data.description || 'default_description',
        floor: data.floor || 'default_floor',
        gps_coordinates: data.gps_coordinates || 'default_gps_coordinates',
    };

    useEffect(() => {
        dispatch(getBranches())
    }, [dispatch]);

    // Get GPS coordinates
    function getCoordinates() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    // Set GPS coordinates to GPS field
    async function handleGetCoordinates(formik) {
        try {
            const position = await getCoordinates();
            const { latitude, longitude } = position.coords;
            formik.setFieldValue('gps_coordinates', `${latitude}, ${longitude}`);
        } catch (error) {
            console.error(error);
        }
    }


    const handleFormSubmit = async (values, onSubmitProps) => {
        // send new form data to backend
        await dispatch(createRoom(values));
        onSubmitProps.resetForm(); // Clear the form
        setOpen(true); // Re-open the alert
    };

    return (
        <Box m="10px">
            <Box mb="1rem">
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {isError && isError.length > 0 ? (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                        {isSuccess && isSuccess.length > 0 ? (
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>`{newRoom.name} room edited!`</strong>
                            </Alert>
                        ) : (null)}
                        {message && message.length > 0 ? (
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                    </Stack>
                </Collapse>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialvalues}
                validationSchema={roomSchema}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                hidden
                                variant="outlined"
                                type="hidden"
                                label="Ref Code"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.ref_code}
                                name="ref_code"
                                error={!!formik.touched.ref_code && !!formik.errors.ref_code}
                                helperText={formik.touched.ref_code && formik.errors.ref_code}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="hidden"
                                label="Slug"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.slug}
                                name="slug"
                                error={!!formik.touched.slug && !!formik.errors.slug}
                                helperText={formik.touched.slug && formik.errors.slug}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                branch="text"
                                label="Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                error={!!formik.touched.name && !!formik.errors.name}
                                helperText={formik.touched.name && formik.errors.name}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                branch="text"
                                label="Description"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                error={!!formik.touched.description && !!formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                branch="text"
                                select label="Branch"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.branch}
                                name="branch"
                                error={!!formik.touched.branch && !!formik.errors.branch}
                                helperText={formik.touched.branch && formik.errors.branch}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {branches.map((branch) => (<MenuItem key={branch.pkid} value={branch.pkid}>{branch.name}</MenuItem>))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="GPS Coordinates"
                                onBlur={formik.formik.handleBlur}
                                onChange={formik.formik.handleChange}
                                value={formik.formik.values.gps_coordinates}
                                name="gps_coordinates"
                                error={formik.formik.touched.gps_coordinates && !!formik.formik.errors.gps_coordinates}
                                helperText={formik.formik.touched.gps_coordinates && formik.formik.errors.gps_coordinates}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <Box display="flex" justifyContent="center" mt="5px">
                                <Button fullWidth color="secondary" variant="contained" onClick={() => handleGetCoordinates(formik)}>
                                    Get GPS Coordinates
                                </Button>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button fullwidth="true" type="submit" color="secondary" variant="contained">
                                Update Room
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default EditRoomForm;
