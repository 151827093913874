import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetAPIService from "./assetAPIService";


const initialState = {
    assets: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};

//get all assets
export const getAssets = createAsyncThunk('assets/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetAPIService.getAssets(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const assetSlice = createSlice({
    name: 'asset',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //get all assets
            .addCase(getAssets.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "Loading in progress...";
            })
            .addCase(getAssets.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.assets = action.payload.results;
                state.message = "Register list loaded successfully!";
            })
            .addCase(getAssets.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetSlice.actions;
export default assetSlice.reducer;