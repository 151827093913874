import axios from "axios";

const CREATE_ASSETMODEL_URL = "/api/v1/entities/asset-models/";
const GET_ASSETMODELS_URL = "/api/v1/entities/asset-models/";
const GET_MAKE_ASSETMODELS_URL = "/api/v1/entities/asset-modelsbymake/";

//get asset models
const getAssetModels = async (token) => {
    const response = await axios.get(GET_ASSETMODELS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get asset models by make
const getMakeAssetModels = async (asset_make, token) => {
    const response = await axios.get(`${GET_MAKE_ASSETMODELS_URL}?asset_make=${asset_make}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create asset model
const createAssetModel = async (assetModelData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ASSETMODEL_URL, assetModelData, config);
    return response.data;
};

const assetModelAPIService = { createAssetModel, getAssetModels, getMakeAssetModels };
export default assetModelAPIService;