import axios from "axios";

const CREATE_ASSETTYPE_URL = "/api/v1/entities/asset-types/";
const GET_ASSETTYPES_URL = "/api/v1/entities/asset-types/";
const GET_SUBCATEGORY_ASSETTYPES_URL = "/api/v1/entities/asset-typesbysubcategory/";

//get asset types
const getAssetTypes = async (token) => {
    const response = await axios.get(GET_ASSETTYPES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//get asset types by subcategory
const getSubCategoryAssetTypes = async (asset_subcategory, token) => {
    const response = await axios.get(`${GET_SUBCATEGORY_ASSETTYPES_URL}?asset_subcategory=${asset_subcategory}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};

//create asset type
const createAssetType = async (assetTypeData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ASSETTYPE_URL, assetTypeData, config);
    return response.data;
};

const assetTypeAPIService = { createAssetType, getAssetTypes, getSubCategoryAssetTypes };

export default assetTypeAPIService;