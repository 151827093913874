import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ictequipmentAPIService from "./ictequipAPIService";


const initialState = {
    ictequipments: [],
    ictequipment: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create ict equipment
export const createIctequipment = createAsyncThunk('icts/create',
    async (ictequipmentData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await ictequipmentAPIService.createIctequipment(ictequipmentData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update ict equipment
export const updateIctequipment = createAsyncThunk('icts/update',
    async ({ ictequipmentData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await ictequipmentAPIService.updateIctequipment(ictequipmentData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all ict equipments
export const getIctequipments = createAsyncThunk('icts/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await ictequipmentAPIService.getIctequipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const ictequipmentSlice = createSlice({
    name: 'ictequipment',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create ict
            .addCase(createIctequipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createIctequipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.ictequipment = action.payload;
                state.message = 'Ict equipment created successfully';
            })
            .addCase(createIctequipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update ict
            .addCase(updateIctequipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateIctequipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.ictequipment = action.payload;
                state.message = 'Ict equipment updated successfully';
            })
            .addCase(updateIctequipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all ict equipment
            .addCase(getIctequipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getIctequipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.ictequipments = action.payload.results;
                state.message = "";
            })
            .addCase(getIctequipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = ictequipmentSlice.actions;
export default ictequipmentSlice.reducer;