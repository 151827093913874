import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetMovementByLocationReportAPIService from './locationMovementAPIService';


const initialState = {
    assetMovementByLocationReports: [],
    assetMovementByLocationReport: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};


// get asset movement by location report
export const getAssetMovementByLocationReport = createAsyncThunk(
    'report/assetMovementByLocationReport',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await assetMovementByLocationReportAPIService.getAssetMovementByLocationReport(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            throw new Error(message);
        }
    }
);

// create slice
const assetMovementByLocationReportSlice = createSlice({
    name: 'assetMovementByLocationReport',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // get asset movement by location report
            .addCase(getAssetMovementByLocationReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAssetMovementByLocationReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.assetMovementByLocationReports = action.payload;
                state.message = 'Asset movement by location report loaded successfully';
            })
            .addCase(getAssetMovementByLocationReport.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = assetMovementByLocationReportSlice.actions;
export default assetMovementByLocationReportSlice.reducer;